import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import PhoneOutlined from '@material-ui/icons/PhoneOutlined';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { LabelContainer, TextField } from 'components';
import { SWMP } from 'models/SWMP';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import { getStepRoute } from 'utils';

interface SwmpGeneralInfoProps {
  swmp: SWMP;
  onUpdate: (swmp: SWMP) => void;
}

export const useStyles = makeStyles({
  datePicker: {
    flex: 1,
  },
  buttonIcon: {
    color: '#FFF',
  },
  error: {
    color: '#F00',
  },
});

const SwmpGeneralInfo: React.FC<SwmpGeneralInfoProps> = ({
  swmp,
  onUpdate,
}) => {
  const [isCalendarYear, setIsCalendarYear] = useState<boolean>(true);
  const { id: swmpId, step, accessType } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const handleChangeGeneralInfo = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = event.target;

    if (name === 'year' && !/^[0-9]*$/.test(value as string)) {
      return;
    }

    onUpdate({
      ...swmp,
      [name as string]: value,
    });
  };

  useEffect(() => {
    if (swmp.annualReportingYear) {
      setIsCalendarYear(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swmp.id]);

  const isView = useMemo(() => {
    return accessType === 'view';
  }, [accessType]);

  return (
    <>
      <TextField
        label="Start Year"
        name="year"
        disabled={isView}
        placeholder="Start Year"
        fullWidth
        value={swmp.year}
        onChange={handleChangeGeneralInfo}
      />
      <TextField
        label="Permit Number"
        name="number"
        disabled={isView}
        placeholder="Federal or state permit number"
        fullWidth
        value={swmp.number || ''}
        onChange={handleChangeGeneralInfo}
      />
      <TextField
        label="Name of MS4"
        name="name"
        disabled={isView}
        placeholder="Organization name"
        fullWidth
        value={swmp.name || ''}
        onChange={handleChangeGeneralInfo}
      />
      <Box mb={3}>
        <Box mb={1}>
          <Typography variant="body1">Operator Level</Typography>
        </Box>
        <FormControl fullWidth variant="outlined">
          <Select
            placeholder="MS4 operator level"
            name="operatorLevel"
            disabled={isView}
            value={swmp.operatorLevel || ''}
            onChange={handleChangeGeneralInfo}>
            {Array.from({ length: 4 }).map((_, level) => (
              <MenuItem key={level} value={level + 1}>
                <Typography color="textSecondary">{level + 1}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <TextField
        label="Contact Name"
        name="contactName"
        disabled={isView}
        placeholder="Primary contact for the MS4 permit"
        fullWidth
        value={swmp.contactName || ''}
        onChange={handleChangeGeneralInfo}
      />
      <Box display="flex" justifyContent="space-between">
        <Box flex="1" mr={3}>
          <TextField
            label="Telephone Number"
            name="phone"
            disabled={isView}
            placeholder="(555) 555-5555"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneOutlined fontSize="small" />
                </InputAdornment>
              ),
            }}
            value={swmp.phone || ''}
            onChange={handleChangeGeneralInfo}
          />
        </Box>
        <Box flex="1">
          <TextField
            label="Email Address"
            name="emailAddress"
            disabled={isView}
            placeholder="admin@city.org"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined fontSize="small" />
                </InputAdornment>
              ),
            }}
            value={swmp.emailAddress || ''}
            onChange={handleChangeGeneralInfo}
          />
        </Box>
      </Box>
      <TextField
        label="Mailing Address"
        name="mailingAddress"
        disabled={isView}
        placeholder="Street Address, City, State Zipcode"
        fullWidth
        value={swmp.mailingAddress || ''}
        onChange={handleChangeGeneralInfo}
      />
      <LabelContainer label="Annual Reporting Year" mt={3}>
        <RadioGroup
          value={isCalendarYear}
          onChange={(e) => {
            const value = JSON.parse(e.target.value);
            setIsCalendarYear(value);
            onUpdate({
              ...swmp,
              annualReportingYear: value
                ? null
                : new Date(parseInt(swmp.year), 11, 31),
            });
          }}>
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="Calendar Year"
            disabled={isView}
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="Other (last day of reporting year)"
            disabled={isView}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disabled={isView || isCalendarYear}
              disableToolbar
              variant="inline"
              format="MM/dd"
              placeholder="mm/dd"
              id="date-picker-inline"
              value={swmp.annualReportingYear}
              onChange={(date: Date | null) =>
                onUpdate({
                  ...swmp,
                  annualReportingYear: date,
                })
              }
              className={classes.datePicker}
              inputVariant="outlined"
              InputAdornmentProps={{ position: 'start' }}
            />
          </MuiPickersUtilsProvider>
        </RadioGroup>
      </LabelContainer>
      <Box mt={6} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          endIcon={
            <ArrowForwardIos className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, null, step, true, accessType))
          }>
          Best Management Practices
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  swmp: select.swmp(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdate: (swmp: SWMP) => dispatch(actions.updateSwmp(swmp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwmpGeneralInfo);
