import { AddModal, SaveModal } from 'components';
import { ModalProps } from 'components/Modal';
import { useMemo } from 'react';

export const useModal = (
  isAddModal: boolean,
  isBmpModal: boolean
): {
  Modal: React.FC<Omit<ModalProps, 'completeActionText'>>;
  title: string;
} => {
  const Modal = useMemo(() => {
    if (isAddModal) {
      return AddModal;
    }

    return SaveModal;
  }, [isAddModal]);

  let title = 'Add Best Management Practice';

  if (!isBmpModal) {
    // in case of MG modal
    title = 'Add Measurable Goal';
  }

  if (!isAddModal) {
    title = title.replace('Add', 'Change');
  }

  return {
    Modal,
    title,
  };
};
