import { BMP } from 'models/BMP';
import { MG } from 'models/MG';

export interface ValueWithDescription {
  value: boolean | null;
  description: string;
}

export const INITIAL_VALUE_WITH_DESCRIPTION: ValueWithDescription = {
  value: null,
  description: '',
};

export interface AnnualReport {
  general: {
    isCopyReportSubmitted: boolean | null;
    reportSubmittedRegion: string;
  };

  complianceStatus: {
    submittedSwmp: ValueWithDescription;
    recordKeepingRequirements: ValueWithDescription;
    eligibilityRequirements: ValueWithDescription;
    conductedAnnualReview: ValueWithDescription;
    generalAssessments: {
      id: string;
      bmp: BMP;
      description: string;
    }[];
    progressReducingPollutants: {
      id: string;
      mg: MG;
      informationUsed: string;
      quantity: string;
      units: string;
      description: string;
    }[];
    successImplementations: {
      id: string;
      mg: MG;
      description: string;
    }[];
  };

  dataSummary: {
    description: string;
  };

  impairedWaterBodes: {
    identifyImpairedWater: string;
    activitiesToDischarge: string;
    implementationOfTargetedControls: string;
    identifiedBenchmarks: {
      id: string;
      benchmarkParameter: string;
      benchmarkValue: string;
      yearsConducted: string;
      description: string;
    }[];
    archievingBenchmarkAnalyses: {
      id: string;
      benchmarkParameterId: string;
      benchmarkParameter: string;
      bmp: BMP;
      description: string;
    }[];
    impairmentBacteriaReports: {
      id: string;
      bmp: BMP;
      description: '';
    }[];
    bmpEffectivenessProgresses: {
      id: string;
      benchmarkParameterId: string;
      benchmarkParameter: string;
      description: '';
    }[];
  };

  stormwaterActivities: {
    activities: {
      id: string;
      bmp: BMP;
      stormwaterActivity: string;
      description: string;
    }[];
  };

  swmpModifications: {
    isImplementationReviewedEachYear: boolean | null;
    hasChanges: boolean | null;
    entityChanges: {
      id: string;
      entity: BMP | MG;
      isBmp: boolean;
      description: string;
    }[];
    additionalChangesDescription: string;
  };

  additionalBmps: {
    additionalBmpsImplementations: {
      id: string;
      bmp: string;
      description: string;
      implementationSchedule: string;
      status: string;
    }[];
  };

  additionalInformation: {
    isPermitteeRelyingOnEntities: boolean | null;
    relyingEntities: {
      id: string;
      name: string;
      explanation: string;
    }[];
    isPermitteePartOfGroup: boolean | null;
    isReportIncludingAllPermittees: boolean | null;
    associatedResponsibilities: {
      id: string;
      authorizationNumber: string;
      permittee: string;
    }[];
  };

  constructionActivities: {
    numberOfConstructionActivities: string;
    utilizeSeventhMcm: boolean | null;
    numberOfMunicipalActivities: string;
  };
}

export const INITIAL_ANNUAL_REPORT: AnnualReport = {
  general: {
    isCopyReportSubmitted: null,
    reportSubmittedRegion: '',
  },

  complianceStatus: {
    submittedSwmp: INITIAL_VALUE_WITH_DESCRIPTION,
    recordKeepingRequirements: INITIAL_VALUE_WITH_DESCRIPTION,
    eligibilityRequirements: INITIAL_VALUE_WITH_DESCRIPTION,
    conductedAnnualReview: INITIAL_VALUE_WITH_DESCRIPTION,
    generalAssessments: [],
    progressReducingPollutants: [],
    successImplementations: [],
  },

  dataSummary: {
    description: '',
  },

  impairedWaterBodes: {
    identifyImpairedWater: '',
    activitiesToDischarge: '',
    implementationOfTargetedControls: '',
    identifiedBenchmarks: [],
    archievingBenchmarkAnalyses: [],
    impairmentBacteriaReports: [],
    bmpEffectivenessProgresses: [],
  },

  stormwaterActivities: {
    activities: [],
  },

  swmpModifications: {
    isImplementationReviewedEachYear: null,
    hasChanges: null,
    entityChanges: [],
    additionalChangesDescription: '',
  },

  additionalBmps: {
    additionalBmpsImplementations: [],
  },

  additionalInformation: {
    isPermitteeRelyingOnEntities: null,
    relyingEntities: [],
    isPermitteePartOfGroup: null,
    isReportIncludingAllPermittees: null,
    associatedResponsibilities: [],
  },

  constructionActivities: {
    numberOfConstructionActivities: '',
    utilizeSeventhMcm: null,
    numberOfMunicipalActivities: '',
  },
};
