import { TextFieldProps as MatTextFieldProps } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MatTextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';

type TextFieldProps = MatTextFieldProps & {
  noMb?: boolean;
  highlightLabel?: boolean;
};

const TextField: React.FC<Omit<TextFieldProps, 'variant'>> = ({
  noMb,
  label,
  disabled,
  highlightLabel = true,
  ...rest
}) => {
  return (
    <Box mb={noMb ? 0 : 3}>
      {label && (
        <Box mb={1}>
          <Typography
            variant="body1"
            color={
              disabled && !highlightLabel ? 'textSecondary' : 'textPrimary'
            }>
            {label}
          </Typography>
        </Box>
      )}

      <MatTextField variant="outlined" disabled={disabled} {...rest} />
    </Box>
  );
};

export default TextField;
