import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import {
  AddButton,
  AddModal,
  LabelContainer,
  SwmpCard,
  TextField,
} from 'components';
import { useValidator } from 'hooks';
import { SWMPStatus, SWMPAddOption } from 'models';
import { Customer } from 'models/Customer';
import { INITIAL_SWMP, SWMP } from 'models/SWMP';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import { createSwmp, fetchSwmpList, exportSwmp } from 'services';
import { downloadFile } from 'utils';

interface SwmpListProps {
  swmpList: SWMP[];
  customer: Customer;
  onLoad: () => Promise<void>;
  onSetError: (error: string) => void;
}

const useStyles = makeStyles({
  header: {
    position: 'relative',
  },
  exportButton: {
    position: 'absolute',
    right: 0,
  },
});

const SwmpList: React.FC<SwmpListProps> = ({
  swmpList,
  customer,
  onLoad,
  onSetError,
}) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [swmp, setSwmp] = useState<SWMP>({
    ...INITIAL_SWMP,
    year: new Date().getFullYear().toString(),
  });
  const [addOption, setAddOption] = useState(SWMPAddOption.Blank);
  const history = useHistory();

  useEffect(() => {
    swmpList.length && setAddOption(SWMPAddOption.Copy);
  }, [swmpList]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const handleNavigate = (swmpId: number, status: SWMPStatus) =>
    history.push(
      status === SWMPStatus.Draft
        ? `/swmp/draft/${swmpId}/general`
        : `swmp/${swmpId}`
    );

  const handleCreateSwmp = async () => {
    try {
      const { id } = await createSwmp(parseInt(swmp.year), addOption);
      history.push(`/swmp/draft/${id}/general`);
    } catch (error) {
      onSetError(error.response.data.message);
    }
  };

  const { validator, handleChange, handleComplete } = useValidator(
    swmp,
    ['year'],
    isModalOpen,
    setSwmp,
    handleCreateSwmp
  );

  const handleExport = async () => {
    const { fileName, fileLink } = await exportSwmp();
    downloadFile(fileName, fileLink);
  };

  return (
    <>
      <Box
        className={classes.header}
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={2}>
        <Typography variant="subtitle1">Stormwater Management Plans</Typography>
        {customer.admin && (
          <Button
            className={classes.exportButton}
            variant="contained"
            color="secondary"
            onClick={handleExport}>
            Export
          </Button>
        )}
      </Box>

      {customer.admin && (
        <>
          <AddButton
            text="Add/Update Stormwater Management Plan"
            fullWidth
            onClick={() => setIsModalOpen(true)}
          />
          {swmpList.map((swmp) => (
            <Box
              key={swmp.id}
              onClick={() => handleNavigate(swmp.id, swmp.status)}>
              <SwmpCard swmp={swmp} />
            </Box>
          ))}
        </>
      )}

      <AddModal
        title="Add Stormwater Management Plan"
        open={isModalOpen}
        onComplete={handleComplete}
        onCancel={() => setIsModalOpen(false)}>
        <TextField
          fullWidth
          label="Start Year"
          name="year"
          value={swmp.year}
          placeholder="2023"
          onChange={handleChange}
          helperText={
            validator['year'] === false ? 'Start year is required' : ''
          }
        />
        <LabelContainer mt={3} label="Add From">
          <RadioGroup
            value={addOption}
            onChange={(e) => setAddOption(e.target.value as SWMPAddOption)}>
            {swmpList.length !== 0 && (
              <FormControlLabel
                value={SWMPAddOption.Copy}
                control={<Radio />}
                label="Copy Previous Stormwater Management Plan"
              />
            )}

            <FormControlLabel
              value={SWMPAddOption.Blank}
              control={<Radio />}
              label="Blank"
            />

            <FormControlLabel
              value={SWMPAddOption.Example}
              control={<Radio />}
              label="Example"
            />
          </RadioGroup>
        </LabelContainer>
      </AddModal>
    </>
  );
};

const mapStateToProps = (state) => ({
  swmpList: select.swmpList(state),
  customer: select.customer(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: async () => {
    const { swmp, customer } = await fetchSwmpList();

    dispatch(actions.setSwmpList(swmp));
    dispatch(actions.setCustomer(customer));
  },
  onSetError: (error: string) => dispatch(actions.setError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwmpList);
