import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import { LabelContainer, TextField } from 'components';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import { getStepRoute } from 'utils';

import { useStyles } from './SwmpComplianceStatus';
import { SwmpAnnualReportPromptProps } from '.';

const SwmpDataSummary: React.FC<SwmpAnnualReportPromptProps> = ({
  annualReport,
  onUpdateAnnualReport,
}) => {
  const { dataSummary } = annualReport;
  const classes = useStyles();
  const history = useHistory();
  const { swmpId, reportId, step } = useParams();

  return (
    <>
      <LabelContainer label="Provide a summary of all information used, including any lab results (if sampling was conducted) to assess the success of the SWMP at reducing the discharge of pollutants to the MEP. For example, did the MS4 conduct visual inspections, clean the inlets, look for illicit discharge, clean streets, look for flow during dry weather, etc.? ">
        <TextField
          fullWidth
          defaultValue={dataSummary.description}
          placeholder="Provide a summary of all information used"
          multiline
          onBlur={(e) => onUpdateAnnualReport('description', e.target.value)}
          rows={5}
        />
      </LabelContainer>

      <Box display="flex" justifyContent="space-between" mt={6}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            <ArrowBackIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, false))
          }>
          Status of Compliance with the MS4 GP and SWMP
        </Button>
        <Button
          variant="contained"
          color="secondary"
          endIcon={
            <ArrowForwardIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, true))
          }>
          Impaired Waterbodies
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  annualReport: select.annualReport(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAnnualReport: (path: string, value: any) =>
    dispatch(actions.updateAnnualReport(`dataSummary.${path}`, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwmpDataSummary);
