import axios, { AxiosResponse } from 'axios';
import { SWMPAddOption } from 'models';
import { Activity } from 'models/Activity';
import { AnnualReportContact } from 'models/AnnualReportContact';
import { BMP } from 'models/BMP';
import { MG } from 'models/MG';
import { SWMP } from 'models/SWMP';

export const fetchSwmpList = () => axios.get('/swmp').then(patchData);

export const fetchSwmpDetails = (swmpId: number, reportId?: number) =>
  axios.get(`/swmpactivities/${swmpId}/${reportId || 0}`).then(patchData);

export const fetchSwmpActivityAttachments = (swmpId: number) =>
  axios.get(`/swmpactivities/${swmpId}/attachments`).then(patchData);

export const fetchAnnualReportStatus = (swmpId: number) =>
  axios.get(`/annualreport/status/${swmpId}`).then(patchData);

export const fetchAnnualReport = (id: number) =>
  axios.get(`/annualreport/${id}`).then(patchData);

export const createSwmp = (year: number, addOption: SWMPAddOption) =>
  axios.post('/swmp', { year, addOption }).then(patchData);

export const createBmp = (bmp: BMP) => axios.post('/bmp', bmp).then(patchData);

export const createMg = (mg: MG & { bmpId: string }) =>
  axios.post('/mg', mg).then(patchData);

export const createActivity = (activity: Activity) =>
  axios.post('/swmpactivities', activity).then(patchData);

export const updateActivity = (activity: Activity) =>
  axios.put('/swmpactivities', activity).then(patchData);

export const createActivityAttachments = async (
  activityId: number,
  attachments: File[]
) => {
  const data = new FormData();
  attachments.forEach((f) => data.append('attachments', f, f.name));

  return axios
    .post(`/swmpactivities/${activityId}/files`, data)
    .then(patchData);
};

export const deleteActivityAttachment = (activityId: string, fileId: number) =>
  axios.delete(`/swmpactivities/${activityId}/files/${fileId}`);

export const createAnnualReport = (swmpId: number, year: number) =>
  axios.post('/annualreport', { swmpId, year }).then(patchData);

export const updateSwmp = (swmp: SWMP) => axios.put('/swmp', swmp);

export const startSwmp = (swmp: SWMP) => axios.put('/swmp/activate', swmp);

export const deleteSwmp = (id: number) => axios.delete(`/swmp/${id}`);

export const updateEntities = (swmp: SWMP, bstMgtPracs: BMP[]) =>
  axios.post('/swmp/updateEntities', {
    swmp,
    bstMgtPracs,
  });

export const extendSwmp = (swmp: SWMP) => axios.put('/swmp/extend', swmp);

export const updateBmp = (bmp: BMP) => axios.put('/bmp', bmp);

export const updateMg = (mg: MG & { bmpId: string }) => axios.put('/mg', mg);

export const deleteBmp = (id: string) => axios.delete(`/bmp/${id}`);

export const deleteMg = (id: string) => axios.delete(`/mg/${id}`);

export const deleteActivity = (id: string) =>
  axios.delete(`/swmpactivities/${id}`);

export const updateAnnualReport = (id: string, report: any) =>
  axios.put('/annualreport', { id, report });

export const updateAnnualReportContact = (
  annualReportContact: AnnualReportContact
) => axios.put('/annualreport/contact', annualReportContact);

export const downloadAnnualReport = (id: number) =>
  axios.post('/reports/swmp-annual', { id }).then(patchData);

export const exportSwmp = () => axios.post('/export/swmp').then(patchData);

const patchData = (res: AxiosResponse) => res.data;
