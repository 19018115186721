import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import StartIcon from '@material-ui/icons/SlideshowOutlined';
import { Modal } from 'components';
import { SWMPStatus } from 'models';
import { BMP } from 'models/BMP';
import { INITIAL_SWMP, SWMP } from 'models/SWMP';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import { startSwmp, updateEntities } from 'services';
import { getStepRoute } from 'utils';

import { useStyles as useGeneralStyles } from './SwmpGeneralInfo';

interface SwmpStartProps {
  swmp: SWMP;
  bstMgtPracs: BMP[];
  onComplete: (
    swmp: SWMP,
    bstMgtPracs: BMP[],
    isUpdate: boolean
  ) => Promise<void>;
  onSetError: (error: string) => void;
}

const useStyles = makeStyles((theme) => ({
  startButton: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
  },
}));

const SwmpStart: React.FC<SwmpStartProps> = ({
  swmp,
  bstMgtPracs,
  onComplete,
  onSetError,
}) => {
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const { id: swmpId, step, accessType } = useParams();

  const handleCompleteSwmp = async () => {
    try {
      setIsPromptModalOpen(false);
      await onComplete(swmp, bstMgtPracs, !!accessType);

      history.push(!!accessType ? `/swmp/${swmpId}` : '/swmp');
    } catch (error) {
      onSetError(error.response.data.message);
    }
  };

  const { title, subTitle, completeText } = useMemo(() => {
    if (!accessType) {
      return {
        title: 'Make this SWMP the new active SWMP by starting',
        subTitle:
          'Once started, all activity will start to be logged against this SWMP.',
        completeText: 'Start',
      };
    }

    return {
      title: 'Update Stormwater Management Plan',
      subTitle: `Updates will be available for all new Stormwater Management
                 Plan activities. Previously recorded activities will retain
                 the Best Management Practice and goals originally applied.`,
      completeText: 'Update',
    };
  }, [accessType]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={12}
        mb={12}>
        <Typography align="center" variant="subtitle1">
          {title}
        </Typography>
        <Box mt={2} mb={5}>
          <Typography align="center" variant="body1" color="textSecondary">
            {subTitle}
          </Typography>
        </Box>
        <Button
          variant="contained"
          className={classes.startButton}
          color="primary"
          disabled={!swmp.year}
          startIcon={
            <StartIcon className={classesGeneral.buttonIcon} fontSize="small" />
          }
          onClick={() => setIsPromptModalOpen(true)}>
          {completeText} MS4 SWMP for {swmp.year}
        </Button>
      </Box>
      <Button
        variant="contained"
        color="secondary"
        startIcon={
          <ArrowBackIcon
            className={classesGeneral.buttonIcon}
            fontSize="small"
          />
        }
        onClick={() =>
          history.push(getStepRoute(swmpId, null, step, false, accessType))
        }>
        Best Management Practices
      </Button>
      <Modal
        open={isPromptModalOpen}
        title={`${completeText} SWMP`}
        completeActionText={completeText}
        onCancel={() => setIsPromptModalOpen(false)}
        onComplete={handleCompleteSwmp}>
        <Typography align="center" variant="subtitle2">
          {title}
        </Typography>
        <Box my={2}>
          <Typography align="center" variant="body1" color="textSecondary">
            {subTitle}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  swmp: select.swmp(state),
  bstMgtPracs: select.bstMgtPracs(state),
});

const mapDispatchToProps = (dispatch) => ({
  onComplete: async (swmp: SWMP, bstMgtPracs: BMP[], isUpdate: boolean) => {
    if (!isUpdate) {
      swmp.status = SWMPStatus.Active;
      await startSwmp(swmp);
    } else {
      await updateEntities(swmp, bstMgtPracs);
    }

    dispatch(actions.updateSwmp(INITIAL_SWMP));
    dispatch(actions.updateBmp([]));
    dispatch(actions.updateMg([]));
  },
  onSetError: (error: string) => dispatch(actions.setError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwmpStart);
