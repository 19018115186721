import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';
import { LabelContainer, TextField } from 'components';
import { BMP } from 'models/BMP';
import { MG } from 'models/MG';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import {
  getDifferentEntities,
  getSortedEntities,
  getStepRoute,
  removeAnnualReportEntity,
} from 'utils';
import * as uuid from 'uuid';

import { useStyles } from './SwmpComplianceStatus';
import { SwmpAnnualReportPromptProps } from '.';

interface SwmpModificationsProps extends SwmpAnnualReportPromptProps {
  bstMgtPracs: BMP[];
  measurableGoals: MG[];
}

const SwmpModifications: React.FC<SwmpModificationsProps> = ({
  bstMgtPracs,
  measurableGoals,
  annualReport,
  onUpdateAnnualReport,
}) => {
  const { swmpModifications } = annualReport;
  const classes = useStyles();
  const history = useHistory();
  const { swmpId, step, reportId } = useParams();
  const [selectedEntityId, setSelectedEntityId] = useState<string>('');

  const entityChanges = getSortedEntities(swmpModifications.entityChanges);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}>
        <Box flexBasis="80%">
          <Typography>
            1. The SWMP and MCM implementation procedures are reviewed each
            year.
          </Typography>
        </Box>
        <RadioGroup
          value={swmpModifications.isImplementationReviewedEachYear}
          onChange={(e) =>
            onUpdateAnnualReport(
              'isImplementationReviewedEachYear',
              e.target.value === 'true'
            )
          }>
          <FormControlLabel label="YES" value={true} control={<Radio />} />
          <FormControlLabel label="NO" value={false} control={<Radio />} />
        </RadioGroup>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}>
        <Box flexBasis="80%">
          <Typography>
            2. Changes have been made or are proposed to the SWMP since the NOI
            or the last annual report, including changes in response to TCEQ’s
            review.
          </Typography>
        </Box>
        <RadioGroup
          value={swmpModifications.hasChanges}
          onChange={(e) =>
            onUpdateAnnualReport('hasChanges', e.target.value === 'true')
          }>
          <FormControlLabel label="YES" value={true} control={<Radio />} />
          <FormControlLabel label="NO" value={false} control={<Radio />} />
        </RadioGroup>
      </Box>

      {swmpModifications.hasChanges && (
        <>
          <LabelContainer label="Report on changes made to measurable goals and BMPs:">
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Box flexBasis="85%">
                <FormControl fullWidth variant="outlined">
                  <Select
                    value={selectedEntityId}
                    displayEmpty
                    onChange={(e) =>
                      setSelectedEntityId(e.target.value as string)
                    }>
                    <MenuItem disabled value="">
                      <Typography color="textSecondary">
                        Select a BMP or MG
                      </Typography>
                    </MenuItem>
                    {getDifferentEntities(
                      bstMgtPracs,
                      swmpModifications.entityChanges.filter((e) => e.isBmp),
                      'entity'
                    ).map((bmp) => (
                      <MenuItem key={`bmp-${bmp.id}`} value={`bmp-${bmp.id}`}>
                        <Typography color="textSecondary">
                          {bmp.number} {bmp.title}
                        </Typography>
                      </MenuItem>
                    ))}
                    {getDifferentEntities(
                      measurableGoals,
                      swmpModifications.entityChanges.filter((e) => !e.isBmp),
                      'entity'
                    ).map((mg) => (
                      <MenuItem key={`mg-${mg.id}`} value={`mg-${mg.id}`}>
                        <Typography color="textSecondary">
                          {mg.number} {mg.title}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Button
                className={classes.addButton}
                variant="contained"
                color="secondary"
                endIcon={
                  <AddIcon className={classes.buttonIcon} fontSize="small" />
                }
                onClick={() => {
                  if (!selectedEntityId) {
                    return;
                  }
                  let selectedEntity;

                  if (selectedEntityId.includes('bmp')) {
                    selectedEntity = bstMgtPracs.find(
                      (bmp) =>
                        parseInt(bmp.id) ===
                        parseInt(selectedEntityId.split('-')[1])
                    );
                  } else {
                    selectedEntity = measurableGoals.find(
                      (mg) =>
                        parseInt(mg.id) ===
                        parseInt(selectedEntityId.split('-')[1])
                    );
                  }

                  const entities = [
                    ...swmpModifications.entityChanges,
                    {
                      id: uuid.v4(),
                      entity: selectedEntity,
                      isBmp: selectedEntityId.includes('bmp'),
                      description: '',
                    },
                  ];

                  onUpdateAnnualReport('entityChanges', entities);
                  setSelectedEntityId('');
                }}>
                Add
              </Button>
            </Box>
          </LabelContainer>

          {entityChanges.map((e, index) => {
            const mcm = e.isBmp
              ? (e.entity as BMP).minimumControlMeasures.join(', ')
              : (e.entity as MG).bmp.minimumControlMeasures.join(', ');

            return (
              <Box key={e.id}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  my={1}>
                  <Box flexBasis="85%" flexWrap="wrap">
                    <Typography variant="body1">
                      MCM: {mcm} • {e.isBmp ? 'BMP' : 'MG'}: {e.entity.number}{' '}
                      {e.entity.title}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() =>
                      removeAnnualReportEntity(
                        'entityChanges',
                        index,
                        swmpModifications,
                        onUpdateAnnualReport
                      )
                    }>
                    <DeleteIcon color="secondary" />
                  </IconButton>
                </Box>
                <Box mt={1}>
                  <TextField
                    fullWidth
                    noMb
                    label="Implemented or Proposed Changes (Submit NOC as needed)"
                    placeholder="Implemented or Proposed Changes (Submit NOC as needed)"
                    multiline
                    rows={5}
                    defaultValue={e.description}
                    onBlur={(e) =>
                      onUpdateAnnualReport(
                        `entityChanges.${index}.description`,
                        e.target.value
                      )
                    }
                  />
                </Box>
              </Box>
            );
          })}
        </>
      )}

      <LabelContainer label="3. Explain additional changes or proposed changes not previously mentioned (i.e. dates, contacts, procedures, annexation of land, etc.)">
        <TextField
          fullWidth
          placeholder="Additional changes or proposed changes not previously mentioned (i.e. dates, contacts, procedures, annexation of land, etc.)"
          multiline
          rows={5}
          defaultValue={swmpModifications.additionalChangesDescription}
          onBlur={(e) =>
            onUpdateAnnualReport('additionalChangesDescription', e.target.value)
          }
        />
      </LabelContainer>

      <Box display="flex" justifyContent="space-between" mt={6}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            <ArrowBackIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, false))
          }>
          Stormwater Activities
        </Button>
        <Button
          variant="contained"
          color="secondary"
          endIcon={
            <ArrowForwardIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, true))
          }>
          Additional BMPs for TMDLs and I-Plans
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  bstMgtPracs: select.bstMgtPracs(state),
  measurableGoals: select.measurableGoals(state),
  annualReport: select.annualReport(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAnnualReport: (path: string, value: any) =>
    dispatch(actions.updateAnnualReport(`swmpModifications.${path}`, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwmpModifications);
