import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { blue, teal } from '@material-ui/core/colors';

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: '1rem',
      },
      colorTextSecondary: {
        color: 'rgba(0, 0, 0, 0.6)',
      },
    },
    MuiDialogTitle: {
      root: {
        textAlign: 'center',
        '& h2': {
          fontSize: `${18 / 16}rem`,
          fontWeight: 400,
        },
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: 0,
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
      spacing: {
        padding: 24,
      },
    },
    MuiSvgIcon: {
      root: {
        color: 'rgba(0, 0, 0, 0.6)',
      },
    },
    MuiFormGroup: {
      root: {
        flexDirection: 'row',
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
        color: '#F00',
      },
    },
    MuiButton: {
      outlinedSecondary: {
        border: 'none !important',
      },
      containedSecondary: {
        color: '#FFF !important',
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 16,
        },
      },
    },
    MuiLink: {
      root: {
        color: 'inherit',
      },
      underlineHover: {
        textDecoration: 'none !important',
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          color: 'rgba(0, 0, 0, 0.7)',
        },
      },
    },
    MuiSelect: {
      root: {
        '& p': {
          lineHeight: 'unset',
        },
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  palette: {
    primary: {
      light: teal[100],
      main: teal[500],
      dark: teal[700],
      contrastText: '#FFF',
    },
    secondary: {
      light: blue[100],
      main: blue[400],
      dark: blue[900],
      contrastText: '#FFF',
    },
  },
  shape: {
    borderRadius: 3,
  },
  typography: {
    fontSize: 14,
    h6: {
      fontSize: `${16 / 16}rem`,
      fontWeight: 400,
    },
    h5: {
      fontSize: `${18 / 16}rem`,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: `${24 / 16}rem`,
    },
    subtitle2: {
      fontSize: `${18 / 16}rem`,
      fontWeight: 400,
    },
    body1: {
      fontSize: `${14 / 16}rem`,
    },
    body2: {
      fontSize: `${12 / 16}rem`,
    },
    button: {
      fontWeight: 400,
    },
  },
});

export default theme;
