export interface MG {
  id: string;
  bmp: {
    id: string;
    number?: string;
    title: string;
    minimumControlMeasures: string[];
  };
  number: string;
  title: string;
  description?: string;
  yearsImplemented: number[];
  isQuantityRequired?: boolean;
  informationUsed?: string;
  units?: string;
  annualGoal?: string;
}

export const INITIAL_MG: MG = {
  id: '',
  bmp: {
    id: '',
    title: '',
    minimumControlMeasures: [],
  },
  number: '',
  title: '',
  description: '',
  yearsImplemented: [],
  isQuantityRequired: false,
  informationUsed: '',
  units: '',
  annualGoal: '',
};
