import { SWMPStatus } from './SWMPStatus';

export interface SWMP {
  id: number;
  year: string;
  number: string;
  name: string;
  operatorLevel: number;
  contactName: string;
  phone: string;
  emailAddress: string;
  mailingAddress: string;
  annualReportingYear: Date | null;
  status: SWMPStatus;
  updatedDate?: Date | null;
  planLength: number;
}

export const INITIAL_SWMP: SWMP = {
  id: 0,
  year: '',
  number: '',
  name: '',
  operatorLevel: 1,
  contactName: '',
  phone: '',
  emailAddress: '',
  mailingAddress: '',
  annualReportingYear: null,
  status: SWMPStatus.Draft,
  updatedDate: null,
  planLength: 5,
};
