import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { TextField } from 'components';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import { getStepRoute } from 'utils';

import { useStyles } from './SwmpComplianceStatus';
import { SwmpAnnualReportPromptProps } from '.';

const SwmpConstructionActivities: React.FC<SwmpAnnualReportPromptProps> = ({
  annualReport,
  onUpdateAnnualReport,
}) => {
  const { constructionActivities } = annualReport;
  const classes = useStyles();
  const history = useHistory();
  const { swmpId, reportId, step } = useParams();
  const [numericFields, setNumericFields] = useState({
    numberOfConstructionActivities:
      constructionActivities.numberOfConstructionActivities,
    numberOfMunicipalActivities:
      constructionActivities.numberOfMunicipalActivities,
  });

  const handleChangeNumericFields = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    if (!/^[0-9]*$/.test(value)) {
      return;
    }

    setNumericFields({
      ...numericFields,
      [event.target.name]: value,
    });
  };

  return (
    <>
      <TextField
        label="1. The number of construction activities that occurred in the jurisdictional area of the MS4 (Large and Small Site Notices submitted by construction site operators)."
        noMb
        fullWidth
        placeholder="Number"
        name="numberOfConstructionActivities"
        value={numericFields.numberOfConstructionActivities}
        onChange={handleChangeNumericFields}
        onBlur={() =>
          onUpdateAnnualReport(
            'numberOfConstructionActivities',
            numericFields.numberOfConstructionActivities
          )
        }
      />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
        mb={1}>
        <Box flexBasis="80%">
          <Typography>
            2.a. Does the permittee utilize the optional seventh MCM related to
            construction?
          </Typography>
        </Box>
        <RadioGroup
          value={constructionActivities.utilizeSeventhMcm}
          onChange={(e) =>
            onUpdateAnnualReport('utilizeSeventhMcm', e.target.value === 'true')
          }>
          <FormControlLabel label="YES" value={true} control={<Radio />} />
          <FormControlLabel label="NO" value={false} control={<Radio />} />
        </RadioGroup>
      </Box>

      {constructionActivities.utilizeSeventhMcm && (
        <TextField
          label="2.b. The number of municipal construction activities authorized under this general permit"
          noMb
          fullWidth
          placeholder="Number"
          name="numberOfMunicipalActivities"
          value={numericFields.numberOfMunicipalActivities}
          onChange={handleChangeNumericFields}
          onBlur={() =>
            onUpdateAnnualReport(
              'numberOfMunicipalActivities',
              numericFields.numberOfMunicipalActivities
            )
          }
        />
      )}

      <Box mt={6}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            <ArrowBackIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, false))
          }>
          Additional Information
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  annualReport: select.annualReport(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAnnualReport: (path: string, value: any) =>
    dispatch(
      actions.updateAnnualReport(`constructionActivities.${path}`, value)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwmpConstructionActivities);
