import { Activity } from 'models/Activity';
import { AnnualReport } from 'models/AnnualReport';
import { AnnualReportContact } from 'models/AnnualReportContact';
import { BMP } from 'models/BMP';
import { Customer } from 'models/Customer';
import { MG } from 'models/MG';
import { Ms4File } from 'models/Ms4File';
import { SWMP } from 'models/SWMP';

/* App State */

export interface AppState {
  loading: boolean;
  customer: Customer;
  swmpList: SWMP[];
  swmp: SWMP;
  bstMgtPracs: BMP[];
  measurableGoals: MG[];
  activities: Activity[];
  activityAttachments: Record<string, Ms4File[]>;
  annualReportYear: number;
  annualReport: AnnualReport;
  annualReportContact: AnnualReportContact;
  error: string;
}

/* Action Types */

export enum ActionTypes {
  SET_SWMP_LIST = 'SET_SWMP_LIST',
  SET_CUSTOMER = 'SET_CUSTOMER',
  SET_ANNUAL_REPORT_YEAR = 'SET_ANNUAL_REPORT_YEAR',
  SET_ANNUAL_REPORT = 'SET_ANNUAL_REPORT',
  UPDATE_SWMP = 'UPDATE_SWMP',
  UPDATE_BMP = 'UPDATE_BMP',
  UPDATE_MG = 'UPDATE_MG',
  UPDATE_ACTIVITY = 'UPDATE_ACTIVITY',
  SET_ACTIVITY_ATTACHMENT = 'SET_ACTIVITY_ATTACHMENT',
  UPDATE_ANNUAL_REPORT = 'UPDATE_ANNUAL_REPORT',
  SET_ERROR = 'SET_ERROR',
  CLEAR_ERROR = 'CLEAR_ERROR',
  SET_LOADING = 'SET_LOADING',
  SET_ANNUAL_REPORT_CONTACT = 'SET_ANNUAL_REPORT_CONTACT',
  UPDATE_ANNUAL_REPORT_CONTACT = 'UPDATE_ANNUAL_REPORT_CONTACT',
}

/* Actions */

interface SetSwmpListAction {
  type: typeof ActionTypes.SET_SWMP_LIST;
  payload: SWMP[];
}

interface SetCustomer {
  type: typeof ActionTypes.SET_CUSTOMER;
  payload: Customer;
}

interface SetAnnualReportYear {
  type: typeof ActionTypes.SET_ANNUAL_REPORT_YEAR;
  payload: number;
}

interface SetAnnualReport {
  type: typeof ActionTypes.SET_ANNUAL_REPORT;
  payload: AnnualReport;
}

interface SetAnnualReportContact {
  type: typeof ActionTypes.SET_ANNUAL_REPORT_CONTACT;
  payload: AnnualReportContact;
}

interface UpdateAnnualReportContactAction {
  type: typeof ActionTypes.UPDATE_ANNUAL_REPORT_CONTACT;
  payload: AnnualReportContact;
}

interface UpdateSwmpAction {
  type: typeof ActionTypes.UPDATE_SWMP;
  payload: SWMP;
}

interface UpdateBmpAction {
  type: typeof ActionTypes.UPDATE_BMP;
  payload: BMP[];
}

interface UpdateMgAction {
  type: typeof ActionTypes.UPDATE_MG;
  payload: MG[];
}

interface UpdateActivityAction {
  type: typeof ActionTypes.UPDATE_ACTIVITY;
  payload: Activity[];
}

interface SetActivityAttachmentAction {
  type: typeof ActionTypes.SET_ACTIVITY_ATTACHMENT;
  payload: Record<string, Ms4File[]>;
}

interface UpdateAnnualReportAction {
  type: typeof ActionTypes.UPDATE_ANNUAL_REPORT;
  payload: { path: string; value: any };
}

interface SetErrorAction {
  type: typeof ActionTypes.SET_ERROR;
  payload: string;
}

interface ClearErrorAction {
  type: typeof ActionTypes.CLEAR_ERROR;
}

interface SetLoadingAction {
  type: typeof ActionTypes.SET_LOADING;
  payload: boolean;
}

export type Actions =
  | SetSwmpListAction
  | SetCustomer
  | SetAnnualReportYear
  | SetAnnualReport
  | UpdateSwmpAction
  | UpdateBmpAction
  | UpdateMgAction
  | UpdateActivityAction
  | SetActivityAttachmentAction
  | UpdateAnnualReportAction
  | SetErrorAction
  | ClearErrorAction
  | SetLoadingAction
  | SetAnnualReportContact
  | UpdateAnnualReportContactAction;
