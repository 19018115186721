export interface AnnualReportContact {
  id: number;
  contactName: string;
  phone: string;
  emailAddress: string;
  mailingAddress: string;
}

export const INITIAL_ANNUAL_REPORT_CONTACT: AnnualReportContact = {
  id: 0,
  contactName: '',
  phone: '',
  emailAddress: '',
  mailingAddress: '',
};
