export const removeAnnualReportEntity = (
  prop: string,
  index: number,
  entity: any,
  callback: (prop: string, entity: any) => void
) => {
  const selectedEntity = entity[prop];
  selectedEntity.splice(index, 1);

  callback(prop, selectedEntity);
};
