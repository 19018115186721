import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import axios from 'axios';
import { App as AppContainer } from 'containers';
import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from 'redux/reducer';

import theme from './theme';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

const store = createStore(reducer);

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <AppContainer />
      </MuiThemeProvider>
    </Provider>
  );
};

export default App;
