import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';
import { LabelContainer, TextField } from 'components';
import { BMP } from 'models/BMP';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import {
  getDifferentEntities,
  getSortedEntities,
  getStepRoute,
  removeAnnualReportEntity,
} from 'utils';
import * as uuid from 'uuid';

import { useStyles } from './SwmpComplianceStatus';
import { SwmpAnnualReportPromptProps } from '.';

interface SwmpActivitiesProps extends SwmpAnnualReportPromptProps {
  bstMgtPracs: BMP[];
}

const SwmpActivities: React.FC<SwmpActivitiesProps> = ({
  bstMgtPracs,
  annualReport,
  onUpdateAnnualReport,
}) => {
  const { stormwaterActivities } = annualReport;
  const classes = useStyles();
  const history = useHistory();
  const { swmpId, step, reportId } = useParams();
  const [selectedBmpId, setSelectedBmpId] = useState<string>('');

  const activities = getSortedEntities(stormwaterActivities.activities, 'bmp');

  return (
    <>
      <LabelContainer label="Describe activities planned for the next reporting period.">
        <Box display="flex" justifyContent="space-between">
          <Box flexBasis="85%">
            <FormControl fullWidth variant="outlined">
              <Select
                value={selectedBmpId}
                displayEmpty
                onChange={(e) => setSelectedBmpId(e.target.value as string)}>
                <MenuItem disabled value="">
                  <Typography color="textSecondary">Select a BMP</Typography>
                </MenuItem>
                {getDifferentEntities(
                  bstMgtPracs,
                  stormwaterActivities.activities,
                  'bmp'
                ).map((bmp) => (
                  <MenuItem key={bmp.id} value={bmp.id}>
                    <Typography color="textSecondary">
                      {bmp.number} {bmp.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Button
            className={classes.addButton}
            variant="contained"
            color="secondary"
            endIcon={
              <AddIcon className={classes.buttonIcon} fontSize="small" />
            }
            onClick={() => {
              if (!selectedBmpId) {
                return;
              }

              const selectedBmp = bstMgtPracs.find(
                (bmp) => bmp.id === selectedBmpId
              );
              const entities = [
                ...stormwaterActivities.activities,
                {
                  id: uuid.v4(),
                  bmp: selectedBmp,
                  stormwaterActivity: '',
                  description: '',
                },
              ];

              onUpdateAnnualReport('activities', entities);
              setSelectedBmpId('');
            }}>
            Add
          </Button>
        </Box>
      </LabelContainer>

      {activities.map((s, index) => (
        <Box key={s.id}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={1}>
            <Box flexBasis="85%">
              <Typography variant="body1">
                MCM: {s.bmp.minimumControlMeasures.join(', ')} • BMP:{' '}
                {s.bmp.number} {s.bmp.title}
              </Typography>
            </Box>
            <IconButton
              onClick={() =>
                removeAnnualReportEntity(
                  'activities',
                  index,
                  stormwaterActivities,
                  onUpdateAnnualReport
                )
              }>
              <DeleteIcon color="secondary" />
            </IconButton>
          </Box>
          <Box mt={1}>
            <TextField
              fullWidth
              label="Stormwater Activity"
              placeholder="Stormwater Activity"
              multiline
              rows={5}
              defaultValue={s.stormwaterActivity}
              onBlur={(e) =>
                onUpdateAnnualReport(
                  `activities.${index}.stormwaterActivity`,
                  e.target.value
                )
              }
            />
          </Box>
          <Box mt={1}>
            <TextField
              fullWidth
              noMb
              label="Description/Comments"
              placeholder="Description/Comments"
              multiline
              rows={5}
              defaultValue={s.description}
              onBlur={(e) =>
                onUpdateAnnualReport(
                  `activities.${index}.description`,
                  e.target.value
                )
              }
            />
          </Box>
        </Box>
      ))}

      <Box display="flex" justifyContent="space-between" mt={6}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            <ArrowBackIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, false))
          }>
          Impaired Waterbodies
        </Button>
        <Button
          variant="contained"
          color="secondary"
          endIcon={
            <ArrowForwardIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, true))
          }>
          SWMP Modifications
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  bstMgtPracs: select.bstMgtPracs(state),
  annualReport: select.annualReport(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAnnualReport: (path: string, value: any) =>
    dispatch(actions.updateAnnualReport(`stormwaterActivities.${path}`, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwmpActivities);
