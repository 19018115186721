import { MG } from './MG';

export interface Activity {
  id?: string;
  swmpId?: number;
  mg?: MG;
  dateAdded?: Date;
  mgId?: string;
  date: Date;
  description: string;
  quantity: string;
}

export const INITIAL_ACTIVITY: Activity = {
  id: '',
  swmpId: 0,
  mgId: '',
  date: new Date(),
  description: '',
  quantity: '',
};
