import { get } from 'lodash';
import { firstBy } from 'thenby';

const sortAlphaNum = (a: string, b: string) =>
  a.localeCompare(b, 'en', { numeric: true });

const sortByMultiple = (
  entities: any[],
  firstSortBy: string,
  secondSortBy: string
) => {
  return entities.sort(
    firstBy((a: any, b: any) =>
      sortAlphaNum(get(a, firstSortBy, ''), get(b, firstSortBy, ''))
    ).thenBy((a: any, b: any) =>
      sortAlphaNum(get(a, secondSortBy, ''), get(b, secondSortBy, ''))
    )
  );
};

export const getSortedEntities = (
  entities: any[],
  sortByEntityType?: string
) => {
  // in case of annual report F > 2
  if (!sortByEntityType) {
    const entitiesByType = entities.reduce(
      (acc, entity) => {
        if (entity.isBmp) {
          acc['bmp'].push(entity);
        } else {
          acc['mg'].push(entity);
        }

        return acc;
      },
      {
        bmp: [],
        mg: [],
      }
    );

    return [
      ...sortByMultiple(entitiesByType.bmp, 'entity.number', 'entity.title'),
      ...sortByMultiple(entitiesByType.mg, 'entity.number', 'entity.title'),
    ];
  }

  return sortByMultiple(
    entities,
    `${sortByEntityType}.number`,
    `${sortByEntityType}.title`
  );
};
