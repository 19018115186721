import { set } from 'lodash/fp';
import { INITIAL_ANNUAL_REPORT } from 'models/AnnualReport';
import { INITIAL_ANNUAL_REPORT_CONTACT } from 'models/AnnualReportContact';
import { INITIAL_SWMP } from 'models/SWMP';
import { getSortedEntities } from 'utils';

import { ActionTypes, Actions, AppState } from './types';

const initialState: AppState = {
  loading: false,
  customer: {
    id: '',
    fullName: '',
    stateAbbr: '',
    annualReport: false,
    admin: false,
  },
  swmpList: [],
  swmp: INITIAL_SWMP,
  bstMgtPracs: [],
  measurableGoals: [],
  activities: [],
  activityAttachments: {},
  annualReportYear: 0,
  annualReport: INITIAL_ANNUAL_REPORT,
  annualReportContact: INITIAL_ANNUAL_REPORT_CONTACT,
  error: '',
};

const reducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.SET_SWMP_LIST:
      return {
        ...state,
        swmpList: action.payload,
      };

    case ActionTypes.SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };

    case ActionTypes.SET_ANNUAL_REPORT_YEAR:
      return {
        ...state,
        annualReportYear: action.payload,
      };

    case ActionTypes.SET_ANNUAL_REPORT:
      return {
        ...state,
        annualReport: action.payload,
      };

    case ActionTypes.SET_ANNUAL_REPORT_CONTACT:
      return {
        ...state,
        annualReportContact: action.payload,
      };

    case ActionTypes.UPDATE_ANNUAL_REPORT_CONTACT:
      return {
        ...state,
        annualReportContact: action.payload || INITIAL_ANNUAL_REPORT_CONTACT,
      };

    case ActionTypes.UPDATE_SWMP:
      return {
        ...state,
        swmp: action.payload || INITIAL_SWMP,
      };

    case ActionTypes.UPDATE_BMP:
      return {
        ...state,
        bstMgtPracs: getSortedEntities(action.payload, 'bmp'),
      };

    case ActionTypes.UPDATE_MG:
      return {
        ...state,
        measurableGoals: getSortedEntities(action.payload, 'mg'),
      };

    case ActionTypes.UPDATE_ACTIVITY:
      const datesMapped = (action.payload || []).map((activity) => ({
        ...activity,
        // dates returned from the server are strings not type `Date`
        // dates added locally are type `Date`
        // Convert string date values to dates
        // Strings from server also include `Z` (zulu) timezone
        // `new Date` will convert this to the local timezone, but these are absolute dates (no timezone in database)
        // Strip the 'Z' to prevent the timezone conversion
        date:
          Object.prototype.toString.call(activity.date) === '[object String]'
            ? new Date((activity.date as any).slice(0, -1))
            : activity.date,
      }));
      return {
        ...state,
        activities: datesMapped,
      };

    case ActionTypes.UPDATE_ANNUAL_REPORT:
      const { path, value } = action.payload;

      return set(`annualReport.${path}`, value, state);

    case ActionTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case ActionTypes.CLEAR_ERROR:
      return {
        ...state,
        error: '',
      };

    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case ActionTypes.SET_ACTIVITY_ATTACHMENT:
      return {
        ...state,
        activityAttachments: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
