import { ButtonProps } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Variant } from '@material-ui/core/styles/createTypography';
import Typography from '@material-ui/core/Typography';
import AddOutlined from '@material-ui/icons/AddOutlined';
import React from 'react';

interface AddButtonProps {
  text: string;
  textVariant?: Variant;
}

const useStyles = makeStyles((theme) => ({
  addButton: {
    display: 'flex',
    alignItems: 'center',
    border: '1px dashed',
    borderColor: theme.palette.grey[300],
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textTransform: 'none',
  },
}));

const AddButton: React.FC<AddButtonProps & ButtonProps> = ({
  text,
  textVariant = 'subtitle2',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button className={classes.addButton} variant="outlined" {...rest}>
      <Box mr={1} display="flex" alignItems="center">
        <AddOutlined />
      </Box>
      <Typography variant={textVariant} color="textSecondary">
        {text}
      </Typography>
    </Button>
  );
};

export default AddButton;
