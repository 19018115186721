import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import { blue, grey } from '@material-ui/core/colors';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  ActivityCard,
  AddButton,
  AddModal,
  LabelContainer,
  Modal,
  Textarea,
} from 'components';
import { useValidator } from 'hooks';
import { cloneDeep } from 'lodash';
import { SWMPStatus } from 'models';
import { Activity, INITIAL_ACTIVITY } from 'models/Activity';
import { BMP } from 'models/BMP';
import { Customer } from 'models/Customer';
import { MG } from 'models/MG';
import { Ms4File } from 'models/Ms4File';
import { SWMP } from 'models/SWMP';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import {
  createActivity,
  createActivityAttachments,
  deleteActivity,
  deleteActivityAttachment,
  extendSwmp,
  updateActivity,
} from 'services';
import { firstBy } from 'thenby';

interface SwmpActivityProps {
  customer: Customer;
  activities: Activity[];
  activityAttachments: Record<string, Ms4File[]>;
  measurableGoals: MG[];
  bstMgtPracs: BMP[];
  swmp: SWMP;
  onExtendSwmp: (swmp: SWMP) => Promise<void>;
  onUpdateActivity: (activities: Activity[]) => void;
  onUpdateActivityAttachment: (attachments: Record<string, Ms4File[]>) => void;
  onSave: (
    prevActivities: Activity[],
    prevActivityAttachments: Record<string, Ms4File[]>,
    activity: Activity,
    attachments: any[],
    successCallback: () => void
  ) => void;
}

export const useStyles = makeStyles((theme) => ({
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2.5),
    borderWidth: 1,
    borderRadius: 3,
    borderColor: '#C4C4C4',
    borderStyle: 'dashed',
    backgroundColor: '#FFF',
    color: '#C4C4C4',
    outline: 'none',
    cursor: 'pointer',
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%',
  },
  error: {
    color: '#F00',
  },
  mcmFilterInput: {
    width: theme.spacing(15),
  },
  bmpFilterInput: {
    width: theme.spacing(20.25),
  },
  datePicker: {
    '& p.Mui-error': {
      display: 'none',
    },
  },
  extend: {
    color: blue[500],
    cursor: 'pointer',
  },
}));

const SwmpActivity: React.FC<SwmpActivityProps> = ({
  activities,
  activityAttachments,
  swmp,
  measurableGoals,
  customer,
  bstMgtPracs,
  onExtendSwmp,
  onSave,
  onUpdateActivity,
  onUpdateActivityAttachment,
}) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasDateError, setHasDateError] = useState(false);
  const [activity, setActivity] = useState<Activity>(INITIAL_ACTIVITY);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [mcmFilter, setMcmFilter] = useState('');
  const [bmpFilter, setBmpFilter] = useState('');
  const [delActivityId, setDelActivityId] = useState('');
  const [delAttachmentIds, setDelAttachmentIds] = useState({
    activityId: '',
    attachmentId: 0,
  });
  const [isOpenExtendSwmpModal, setIsOpenExtendSwmpModal] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    year: '',
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) =>
      setAttachments(
        activity.id ? [...attachments, ...acceptedFiles] : acceptedFiles
      ),
  });
  const { id: swmpId } = useParams();

  useEffect(() => {
    if (!swmp.id) {
      return;
    }

    const currentYear = new Date().getFullYear() - parseInt(swmp.year) + 1;

    if (currentYear > swmp.planLength) {
      setSnackbar({
        open: true,
        year: currentYear.toString(),
      });
    }
  }, [swmp]);

  const thumbs = attachments.map((file, index) => {
    let blob = require('assets/thumbnail.png');

    if (file.id && file.mimeType.includes('image')) {
      blob = file.fileUrl;
    } else if (!file.id && file.type.includes('image')) {
      blob = URL.createObjectURL(file);
    }

    return (
      <Box
        key={index}
        mt={1}
        mr={1}
        width={100}
        height={100}
        padding={0.5}
        boxSizing="border-box"
        display="inline-flex"
        borderRadius={2}
        border={`1px solid ${grey[300]}`}>
        <Box display="flex" minWidth={0} overflow="hidden">
          <img src={blob} className={classes.img} alt="attachments" />
        </Box>
      </Box>
    );
  });

  const initializeStates = () => {
    setIsModalOpen(false);
    setActivity(INITIAL_ACTIVITY);
    setAttachments([]);
  };

  const handleChangeActivityDate = (date: Date | null) => {
    const year = date?.getFullYear();
    const swmpStartYear = parseInt(swmp.year);

    setActivity({
      ...activity,
      date: date as Date,
    });

    // invalid activity date
    if (
      !year ||
      year < swmpStartYear ||
      year > swmpStartYear + swmp.planLength
    ) {
      return setHasDateError(true);
    }

    if (hasDateError) {
      setHasDateError(false);
    }
  };

  const handleCompleteActivity = async () => {
    if (hasDateError) {
      return;
    }

    onSave(
      activities,
      activityAttachments,
      {
        ...activity,
        swmpId,
      },
      attachments,
      initializeStates
    );
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      open: false,
      year: '',
    });
  };

  const handleExtendSwmp = async () => {
    swmp.planLength = new Date().getFullYear() - parseInt(swmp.year) + 1;

    await onExtendSwmp(swmp);
    setIsOpenExtendSwmpModal(false);
  };

  const handleClickActivity = (activity: Activity) => {
    setIsModalOpen(true);
    setActivity(activity);
    setAttachments(activityAttachments[activity.id as string] || []);
  };

  const handleDeleteActivity = () => {
    const updateActivities = activities;
    const index = updateActivities.findIndex((a) => a.id === delActivityId);

    updateActivities.splice(index, 1);
    onUpdateActivity([...updateActivities]);
    deleteActivity(delActivityId);
    setDelActivityId('');
  };

  const handleDeleteAttachment = () => {
    const { activityId, attachmentId } = delAttachmentIds;
    const attachments = activityAttachments;
    const index = attachments[activityId].findIndex(
      (a) => a.id === attachmentId
    );
    attachments[activityId].splice(index, 1);

    onUpdateActivityAttachment({ ...attachments });
    deleteActivityAttachment(activityId, attachmentId);
    setDelAttachmentIds({
      activityId: '',
      attachmentId: 0,
    });
  };

  const { validator, handleChange, handleComplete } = useValidator(
    activity,
    ['mgId'],
    isModalOpen,
    setActivity,
    handleCompleteActivity
  );

  const quantityRequiredMg = useMemo(() => {
    if (!activity.mgId) {
      return null;
    }

    const selectedMg = measurableGoals.find((mg) => mg.id === activity.mgId)!;

    if (!selectedMg.isQuantityRequired) {
      return null;
    }

    return selectedMg;
  }, [activity.mgId, measurableGoals]);

  const filteredActivities = useMemo(() => {
    let matchedBmps: BMP[] = bstMgtPracs;
    let sortedActivities = activities.map((activity) => ({
      ...activity,
      mg: measurableGoals.find((m) => m.id === activity.mgId),
    }));

    if (bmpFilter || mcmFilter) {
      sortedActivities = sortedActivities.sort(
        firstBy((a: Activity, b: Activity) =>
          (a.mg?.bmp.number as string).localeCompare(
            b.mg?.bmp.number as string,
            undefined,
            {
              numeric: true,
              sensitivity: 'base',
            }
          )
        )
          .thenBy((a: Activity, b: Activity) =>
            (a.mg?.number as string).localeCompare(
              b.mg?.number as string,
              undefined,
              {
                numeric: true,
                sensitivity: 'base',
              }
            )
          )
          .thenBy((a: Activity, b: Activity) => {
            const firstDate = new Date(a.date as Date);
            const secondDate = new Date(b.date as Date);

            if (firstDate < secondDate) {
              return 1;
            }

            if (firstDate > secondDate) {
              return -1;
            }

            return 0;
          })
      );
    } else {
      sortedActivities = sortedActivities.sort((a: Activity, b: Activity) => {
        const firstDate = new Date(a.date as Date);
        const secondDate = new Date(b.date as Date);

        if (firstDate < secondDate) {
          return 1;
        }

        if (firstDate > secondDate) {
          return -1;
        }

        return 0;
      });
    }

    if (bmpFilter) {
      matchedBmps = matchedBmps.filter((b) => b.id === bmpFilter);
    }

    if (mcmFilter) {
      matchedBmps = matchedBmps.filter((b) =>
        b.minimumControlMeasures.includes(mcmFilter)
      );
    }

    return sortedActivities.filter((t) =>
      matchedBmps.some((b) => b.measurableGoals.some((m) => m.id === t.mgId))
    );
  }, [mcmFilter, bmpFilter, activities, measurableGoals, bstMgtPracs]);

  return (
    <>
      {customer.admin && swmp.status === SWMPStatus.Active && (
        <AddButton
          fullWidth
          text="Log an Activity"
          onClick={() => setIsModalOpen(true)}
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={3}>
        <Typography variant="h6">Activities</Typography>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Typography variant="body1">Filter:</Typography>
          </Box>
          <Box mr={2}>
            <FormControl
              className={classes.mcmFilterInput}
              fullWidth
              variant="outlined">
              <Select
                value={mcmFilter}
                displayEmpty
                onChange={(e) => setMcmFilter(e.target.value as string)}>
                <MenuItem value={''}>
                  <Typography color="textSecondary">MCM</Typography>
                </MenuItem>
                {Array.from({ length: 6 }).map((_, index) => (
                  <MenuItem key={index} value={`${index + 1}`}>
                    {index + 1}
                  </MenuItem>
                ))}
                <MenuItem value="TMDL">TMDL</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <FormControl className={classes.bmpFilterInput} variant="outlined">
            <Select
              value={bmpFilter}
              displayEmpty
              onChange={(e) => setBmpFilter(e.target.value as string)}>
              <MenuItem value={''}>
                <Typography color="textSecondary">BMP</Typography>
              </MenuItem>
              {bstMgtPracs.map((b) => (
                <MenuItem key={b.id} value={b.id}>
                  {b.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box mt={3}>
        {filteredActivities.map((activity) => (
          <ActivityCard
            key={activity.id}
            username={customer.fullName}
            activity={activity}
            attachments={activityAttachments[activity.id as string]}
            onClick={() => handleClickActivity(activity)}
            onDelete={setDelActivityId}
            onDeleteAttachment={(activityId, attachmentId) =>
              setDelAttachmentIds({ activityId, attachmentId })
            }
          />
        ))}
      </Box>

      <AddModal
        title="Add Activity"
        open={isModalOpen}
        onComplete={handleComplete}
        onCancel={initializeStates}>
        <LabelContainer label="Activity Date" mb={1}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              placeholder="mm/dd"
              className={classes.datePicker}
              value={activity.date}
              onChange={handleChangeActivityDate}
              inputVariant="outlined"
              InputAdornmentProps={{ position: 'start' }}
            />
          </MuiPickersUtilsProvider>
          {hasDateError && (
            <Typography className={classes.error} variant="body2">
              Invalid Activity Date
            </Typography>
          )}
        </LabelContainer>

        <LabelContainer label="Measurable Goal">
          <FormControl fullWidth variant="outlined">
            <Select
              placeholder="Select a Measurable Goal"
              value={activity.mgId}
              name="mgId"
              displayEmpty
              onChange={handleChange}>
              <MenuItem disabled value="">
                <Typography color="textSecondary">
                  Select a Measurable Goal
                </Typography>
              </MenuItem>
              {measurableGoals.map((mg) => (
                <MenuItem key={mg.id} value={mg.id}>
                  <Typography color="textSecondary">
                    {mg.number} {mg.bmp.title} • {mg.title}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {validator['mgId'] === false && (
            <Typography className={classes.error} variant="body2">
              Measurable goal is required
            </Typography>
          )}
        </LabelContainer>

        <LabelContainer label="Quantity" disabled={!quantityRequiredMg}>
          <OutlinedInput
            fullWidth
            name="quantity"
            value={activity.quantity}
            onChange={handleChange}
            disabled={!quantityRequiredMg}
            endAdornment={
              <InputAdornment position="end">
                {quantityRequiredMg ? quantityRequiredMg.units : ''}
              </InputAdornment>
            }
          />
        </LabelContainer>

        <Box mt={3}>
          <Textarea
            label="Description"
            name="description"
            value={activity.description}
            placeholder="Provide a description of the activity that was performed"
            onChange={handleChange}
          />
        </Box>

        <LabelContainer label="Attachments">
          <div
            {...getRootProps({
              className: classes.dropzone,
            })}>
            <input {...getInputProps()} />
            <Typography>
              Drag & Drop or{' '}
              <Typography component="span" color="secondary">
                Browse
              </Typography>
            </Typography>
          </div>
          <Box display="flex" flexWrap="wrap" mt={2}>
            {thumbs}
          </Box>
        </LabelContainer>
      </AddModal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}>
        <SnackbarContent
          message={`SWMP is now in year ${snackbar.year}. Would you like to extend Best Management Practices and Measurable Goals to year ${snackbar.year}`}
          action={
            <Typography
              className={classes.extend}
              onClick={() => {
                setIsOpenExtendSwmpModal(true);
                handleCloseSnackbar();
              }}>
              Extend
            </Typography>
          }
        />
      </Snackbar>

      <Modal
        open={isOpenExtendSwmpModal}
        title="Extend Stormwater Management Plan"
        completeActionText="Extend"
        onCancel={() => setIsOpenExtendSwmpModal(false)}
        onComplete={handleExtendSwmp}>
        <Box my={2}>
          <Typography align="center" variant="body1" color="textSecondary">
            Once started, all activity will start to be logged against this
            SWMP. Please review that all general information and best management
            practices are correct. These cannot be changed once started.
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={!!delActivityId || !!delAttachmentIds.attachmentId}
        title="Are you sure you want to delete?"
        completeActionText="Delete"
        onCancel={() =>
          !!delActivityId
            ? setDelActivityId('')
            : setDelAttachmentIds({
                activityId: '',
                attachmentId: 0,
              })
        }
        onComplete={() =>
          !!delActivityId ? handleDeleteActivity() : handleDeleteAttachment()
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  customer: select.customer(state),
  swmp: select.swmp(state),
  activities: select.activities(state),
  activityAttachments: select.activityAttachments(state),
  measurableGoals: select.measurableGoals(state),
  bstMgtPracs: select.bstMgtPracs(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateActivity: (activities: Activity[]) =>
    dispatch(actions.updateActivity(activities)),
  onUpdateActivityAttachment: (attachments: Record<string, Ms4File[]>) =>
    dispatch(actions.setActivityAttachment(attachments)),
  onSave: (
    prevActivities: Activity[],
    prevActivityAttachments: Record<string, Ms4File[]>,
    activity: Activity,
    attachments: any[],
    successCallback: () => void
  ) => {
    const postActivity = activity.id ? updateActivity : createActivity;

    postActivity(activity)
      .then(async (id) => {
        try {
          let files = attachments;
          let activities = prevActivities;
          const activityAttachments = prevActivityAttachments;

          const newFileIndex = files.findIndex((t) => !t.id);

          if (newFileIndex !== -1) {
            const newFiles = files.slice(newFileIndex, files.length);
            const newMs4Files = await createActivityAttachments(id, newFiles);
            files.splice(newFileIndex, newMs4Files.length, ...newMs4Files);
          }

          const payload = { ...activity, id };

          if (activity.id) {
            const index = activities.findIndex((t) => t.id === activity.id);
            activities[index] = payload;
          } else {
            activities = [payload, ...activities];
          }

          activityAttachments[id] = files;

          dispatch(actions.updateActivity([...activities]));
          dispatch(actions.setActivityAttachment({ ...activityAttachments }));
          successCallback();
        } catch (error) {
          dispatch(actions.setError(error.response.data.message));
          deleteActivity(id);
        }
      })
      .catch((error) => {
        dispatch(actions.setError(error.response.data.message));
      });
  },
  onExtendSwmp: async (swmp: SWMP) => {
    await extendSwmp(swmp);
    dispatch(actions.updateSwmp(cloneDeep(swmp)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SwmpActivity);
