export const downloadFile = (fileName: string, url: string) => {
  const linkNode = document.createElement('a');
  document.body.appendChild(linkNode);
  linkNode.href = url;
  linkNode.download = fileName;
  linkNode.click();
  setTimeout(() => {
    document.body.removeChild(linkNode);
  }, 0);
};
