import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { teal } from '@material-ui/core/colors';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';
import { LabelContainer, TextField } from 'components';
import { camelCase } from 'lodash';
import { Activity } from 'models/Activity';
import { BMP } from 'models/BMP';
import { COMPLIANCE_STATUS } from 'models/constants';
import { MG } from 'models/MG';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import {
  getDifferentEntities,
  getSortedEntities,
  getStepRoute,
  removeAnnualReportEntity,
} from 'utils';
import * as uuid from 'uuid';

import { SwmpAnnualReportPromptProps } from '.';

interface SwmpComplianceStatusProps extends SwmpAnnualReportPromptProps {
  annualReportYear: number;
  bstMgtPracs: BMP[];
  measurableGoals: MG[];
  activities: Activity[];
}

export const useStyles = makeStyles((theme) => ({
  addButton: {
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.75),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  buttonIcon: {
    color: '#FFF',
  },
  incompletedQuantity: {
    color: '#F00',
  },
  completedQuantity: {
    color: teal[600],
  },
}));

const SwmpComplianceStatus: React.FC<SwmpComplianceStatusProps> = ({
  annualReportYear,
  activities,
  bstMgtPracs,
  measurableGoals,
  annualReport,
  onUpdateAnnualReport,
}) => {
  const { complianceStatus } = annualReport;
  const classes = useStyles();
  const history = useHistory();
  const { swmpId, reportId, step } = useParams();
  const [generalAssessmentBmpId, setGeneralAssessmentBmpId] = useState<string>(
    ''
  );
  const [
    progressReducingPollutantsMgId,
    setProgressReducingPollutantsMgId,
  ] = useState<string>('');
  const [successImplementationMgId, setSuccessImplementationMgId] = useState<
    string
  >('');

  const generalAssessments = getSortedEntities(
    complianceStatus.generalAssessments,
    'bmp'
  );

  const progressReducingPollutants = getSortedEntities(
    complianceStatus.progressReducingPollutants,
    'mg'
  );

  const successImplementations = getSortedEntities(
    complianceStatus.successImplementations,
    'mg'
  );

  return (
    <>
      <LabelContainer label="1. Provide information on the status of complying with permit conditions: (TXR040000 Part IV.B.2)">
        {COMPLIANCE_STATUS.map((status, index) => {
          const prop = camelCase(status.permitCondition);

          return (
            <Box key={index}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <Box flexBasis="80%">
                  <Typography>{status.text}</Typography>
                </Box>
                <RadioGroup
                  value={complianceStatus[prop].value}
                  onChange={(e) =>
                    onUpdateAnnualReport(
                      `${prop}.value`,
                      e.target.value === 'true'
                    )
                  }>
                  <FormControlLabel
                    label="YES"
                    value={true}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    label="NO"
                    value={false}
                    control={<Radio />}
                  />
                </RadioGroup>
              </Box>
              <TextField
                fullWidth
                label="Explain"
                defaultValue={complianceStatus[prop].description}
                onBlur={(e) =>
                  onUpdateAnnualReport(`${prop}.description`, e.target.value)
                }
                placeholder="Provide information on the status of complying with permit conditions"
                multiline
                rows={5}
              />
            </Box>
          );
        })}
      </LabelContainer>

      <LabelContainer label="2. Provide a general assessment of the appropriateness of the selected BMPs. You may use the table below to meet this requirement (see Example 1 in instructions)">
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Box flexBasis="85%">
            <FormControl fullWidth variant="outlined">
              <Select
                value={generalAssessmentBmpId}
                name="mgId"
                displayEmpty
                onChange={(e) =>
                  setGeneralAssessmentBmpId(e.target.value as string)
                }>
                <MenuItem disabled value={''}>
                  <Typography color="textSecondary">Select a BMP</Typography>
                </MenuItem>
                {getDifferentEntities(
                  bstMgtPracs,
                  complianceStatus.generalAssessments,
                  'bmp'
                ).map((bmp) => (
                  <MenuItem key={bmp.id} value={bmp.id}>
                    <Typography color="textSecondary">
                      {bmp.number} {bmp.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Button
            className={classes.addButton}
            variant="contained"
            color="secondary"
            endIcon={
              <AddIcon className={classes.buttonIcon} fontSize="small" />
            }
            onClick={() => {
              if (!generalAssessmentBmpId) {
                return;
              }

              const selectedBmp = bstMgtPracs.find(
                (bmp) => bmp.id === generalAssessmentBmpId
              );
              const entities = [
                ...complianceStatus.generalAssessments,
                {
                  id: uuid.v4(),
                  bmp: selectedBmp,
                  description: '',
                },
              ];

              onUpdateAnnualReport('generalAssessments', entities);
              setGeneralAssessmentBmpId('');
            }}>
            Add
          </Button>
        </Box>
      </LabelContainer>

      {generalAssessments.map((g, index) => (
        <Box key={g.id}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={1}>
            <Box flexBasis="85%" flexWrap="wrap">
              <Typography variant="body1">
                MCM: {g.bmp.minimumControlMeasures.join(', ')} • BMP:{' '}
                {g.bmp.number} {g.bmp.title}
              </Typography>
            </Box>
            <IconButton
              onClick={() =>
                removeAnnualReportEntity(
                  'generalAssessments',
                  index,
                  complianceStatus,
                  onUpdateAnnualReport
                )
              }>
              <DeleteIcon color="secondary" />
            </IconButton>
          </Box>
          <Box>
            <TextField
              fullWidth
              noMb
              label="BMP is appropriate for reducing the discharge of pollutants in stormwater (Answer Yes or No and explain)"
              placeholder="BMP is appropriate for reducing the discharge of pollutants in stormwater (Answer Yes or No and explain)"
              multiline
              rows={5}
              defaultValue={g.description}
              onBlur={(e) =>
                onUpdateAnnualReport(
                  `generalAssessments.${index}.description`,
                  e.target.value
                )
              }
            />
          </Box>
        </Box>
      ))}

      <LabelContainer label="3. Describe progress towards reducing the discharge of pollutants to the maximum extent practicable. Summarize any information used (such as visual observation, amount of materials removed or prevented from entering the MS4, or, if required, monitoring data, etc.) to evaluate reductions in the discharge of pollutants. You may use the table below to meet this requirement  (see Example 2 in instructions)">
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Box flexBasis="85%">
            <FormControl fullWidth variant="outlined">
              <Select
                value={progressReducingPollutantsMgId}
                displayEmpty
                onChange={(e) =>
                  setProgressReducingPollutantsMgId(e.target.value as string)
                }>
                <MenuItem disabled value="">
                  <Typography color="textSecondary">
                    Select a Measurable Goal
                  </Typography>
                </MenuItem>
                {getDifferentEntities(
                  measurableGoals,
                  complianceStatus.progressReducingPollutants,
                  'mg'
                ).map((mg) => (
                  <MenuItem key={mg.id} value={mg.id}>
                    <Typography color="textSecondary">
                      {mg.number} {mg.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Button
            className={classes.addButton}
            variant="contained"
            color="secondary"
            endIcon={
              <AddIcon className={classes.buttonIcon} fontSize="small" />
            }
            onClick={() => {
              if (!progressReducingPollutantsMgId) {
                return;
              }

              const selectedMg = measurableGoals.find(
                (mg) => mg.id === progressReducingPollutantsMgId
              );
              const entities = [
                ...complianceStatus.progressReducingPollutants,
                {
                  id: uuid.v4(),
                  mg: selectedMg,
                  informationUsed: selectedMg?.informationUsed,
                  quantity: selectedMg?.annualGoal,
                  units: selectedMg?.units,
                  description: '',
                },
              ];

              onUpdateAnnualReport('progressReducingPollutants', entities);
              setProgressReducingPollutantsMgId('');
            }}>
            Add
          </Button>
        </Box>
      </LabelContainer>

      {progressReducingPollutants.map((p, index) => {
        const annualGoal = measurableGoals.find((mg) => mg.id === p.mg.id)
          ?.annualGoal;
        const totalQuantity = activities
          .filter((t) => t.mgId === p.mg.id)
          .reduce((acc, t) => {
            if (new Date(t.date).getFullYear() === annualReportYear) {
              acc += parseInt(t.quantity);
            }
            return acc;
          }, 0);
        const totalQuantityPercents =
          Math.floor(
            (totalQuantity / parseInt(annualGoal as string)) * 100 * 100
          ) / 100;

        return (
          <Box key={p.id}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              my={1}>
              <Box flexBasis="85%">
                <Typography variant="body1">
                  MCM: {p.mg.bmp.minimumControlMeasures.join(', ')} • MG:{' '}
                  {p.mg.number} {p.mg.title}
                  {p.informationUsed &&
                    ` • Information Used: ${p.informationUsed}`}
                  {annualGoal && ` • Annual Goal: ${annualGoal}`}
                  {p.units && ` • Units: ${p.units}`}
                </Typography>
                {p.mg.isQuantityRequired && (
                  <Typography variant="body1" color="textSecondary">
                    {totalQuantity} of {annualGoal}
                    <span
                      className={
                        totalQuantityPercents < 100
                          ? classes.incompletedQuantity
                          : classes.completedQuantity
                      }>
                      {` (${totalQuantityPercents}%) `}
                    </span>
                    {p.mg.informationUsed} Marked this reporting period
                  </Typography>
                )}
              </Box>
              <IconButton
                onClick={() =>
                  removeAnnualReportEntity(
                    'progressReducingPollutants',
                    index,
                    complianceStatus,
                    onUpdateAnnualReport
                  )
                }>
                <DeleteIcon color="secondary" />
              </IconButton>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Box flexBasis="40%">
                <TextField
                  noMb
                  fullWidth
                  label="Information Used"
                  placeholder="Information Used"
                  defaultValue={p.informationUsed || ''}
                  onBlur={(e) =>
                    onUpdateAnnualReport(
                      `progressReducingPollutants.${index}.informationUsed`,
                      e.target.value
                    )
                  }
                />
              </Box>

              <Box flexBasis="25%">
                <TextField
                  noMb
                  fullWidth
                  label="Quantity"
                  placeholder="Quantity"
                  defaultValue={totalQuantity}
                  onBlur={(e) =>
                    onUpdateAnnualReport(
                      `progressReducingPollutants.${index}.quantity`,
                      e.target.value
                    )
                  }
                />
              </Box>

              <Box flexBasis="25%">
                <TextField
                  noMb
                  fullWidth
                  label="Units"
                  placeholder="Units"
                  defaultValue={p.units || ''}
                  onBlur={(e) =>
                    onUpdateAnnualReport(
                      `progressReducingPollutants.${index}.units`,
                      e.target.value
                    )
                  }
                />
              </Box>
            </Box>
            <TextField
              fullWidth
              noMb
              defaultValue={p.description}
              label="Does the BMP Demonstrate a Direct Reduction in Pollutants? (Answer Yes or No and explain)"
              placeholder="Does the BMP Demonstrate a Direct Reduction in Pollutants? (Answer Yes or No and explain)"
              multiline
              rows={5}
              onBlur={(e) =>
                onUpdateAnnualReport(
                  `progressReducingPollutants.${index}.description`,
                  e.target.value
                )
              }
            />
          </Box>
        );
      })}

      <LabelContainer label="4. Provide the measurable goals for each of the MCMs, and an evaluation of the success of the implementation of the measurable goals (see Example 3 in instructions)">
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Box flexBasis="85%">
            <FormControl fullWidth variant="outlined">
              <Select
                value={successImplementationMgId}
                displayEmpty
                onChange={(e) =>
                  setSuccessImplementationMgId(e.target.value as string)
                }>
                <MenuItem disabled value="">
                  <Typography color="textSecondary">
                    Select a Measurable Goal
                  </Typography>
                </MenuItem>
                {getDifferentEntities(
                  measurableGoals,
                  complianceStatus.successImplementations,
                  'mg'
                ).map((mg) => (
                  <MenuItem key={mg.id} value={mg.id}>
                    <Typography color="textSecondary">
                      {mg.number} {mg.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Button
            className={classes.addButton}
            variant="contained"
            color="secondary"
            endIcon={
              <AddIcon className={classes.buttonIcon} fontSize="small" />
            }
            onClick={() => {
              if (!successImplementationMgId) {
                return;
              }

              const selectedMg = measurableGoals.find(
                (mg) => mg.id === successImplementationMgId
              );
              const entities = [
                ...complianceStatus.successImplementations,
                {
                  id: uuid.v4(),
                  mg: selectedMg,
                  description: '',
                },
              ];

              onUpdateAnnualReport('successImplementations', entities);
              setSuccessImplementationMgId('');
            }}>
            Add
          </Button>
        </Box>
      </LabelContainer>

      {successImplementations.map((s, index) => {
        const annualGoal = measurableGoals.find((mg) => mg.id === s.mg.id)
          ?.annualGoal;
        const totalQuantity = activities
          .filter((t) => t.mgId === s.mg.id)
          .reduce((acc, t) => {
            if (new Date(t.date).getFullYear() === annualReportYear) {
              acc += parseInt(t.quantity);
            }
            return acc;
          }, 0);
        const totalQuantityPercents =
          Math.floor(
            (totalQuantity / parseInt(annualGoal as string)) * 100 * 100
          ) / 100;

        return (
          <Box key={s.id}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              my={1}>
              <Box flexBasis="85%">
                <Typography variant="body1">
                  MCM: {s.mg.bmp.minimumControlMeasures.join(', ')} • MG:{' '}
                  {s.mg.number} {s.mg.title}
                </Typography>
                {s.mg.isQuantityRequired && (
                  <Typography variant="body1" color="textSecondary">
                    {totalQuantity} of {annualGoal}
                    <span
                      className={
                        totalQuantityPercents < 100
                          ? classes.incompletedQuantity
                          : classes.completedQuantity
                      }>
                      {` (${totalQuantityPercents}%) `}
                    </span>
                    {s.mg.informationUsed} this reporting period
                  </Typography>
                )}
              </Box>
              <IconButton
                onClick={() =>
                  removeAnnualReportEntity(
                    'successImplementations',
                    index,
                    complianceStatus,
                    onUpdateAnnualReport
                  )
                }>
                <DeleteIcon color="secondary" />
              </IconButton>
            </Box>
            <TextField
              fullWidth
              noMb
              defaultValue={s.description}
              label="Explain progress toward goal or how goal was achieved. If goal was not accomplished, please explain."
              placeholder="Explain progress toward goal or how goal was achieved. If goal was not accomplished, please explain."
              multiline
              rows={5}
              onBlur={(e) =>
                onUpdateAnnualReport(
                  `successImplementations.${index}.description`,
                  e.target.value
                )
              }
            />
          </Box>
        );
      })}

      <Box display="flex" justifyContent="space-between" mt={6}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            <ArrowBackIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, false))
          }>
          General Information
        </Button>
        <Button
          variant="contained"
          color="secondary"
          endIcon={
            <ArrowForwardIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, true))
          }>
          Stormwater Data Summary
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  bstMgtPracs: select.bstMgtPracs(state),
  measurableGoals: select.measurableGoals(state),
  activities: select.activities(state),
  annualReportYear: select.annualReportYear(state),
  annualReport: select.annualReport(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAnnualReport: (path: string, value: any) =>
    dispatch(actions.updateAnnualReport(`complianceStatus.${path}`, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwmpComplianceStatus);
