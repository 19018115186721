import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { camelCase } from 'lodash';
import { SWMPAddStep, SWMPAnnulReportStep } from 'models';
import { SWMPAddStepInfo, SWMPAnnualReportStepInfo } from 'models/constants';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export interface SwmpStepProps {
  swmpId: number;
  currentStep: SWMPAddStep | SWMPAnnulReportStep;
  totalSteps: (SWMPAddStepInfo | SWMPAnnualReportStepInfo)[];
  reportId?: number;
}

const useStyles = makeStyles((theme) => ({
  stepButton: {
    borderRadius: 100,
    textTransform: 'unset',
    fontWeight: 400,
  },
  stepDivider: {
    flexBasis: 20,
    height: 2,
    background: theme.palette.secondary.light,
  },
  doneStep: {
    '& button': {
      background: theme.palette.secondary.main,
    },
    // styling for step divider
    '& + div': {
      background: theme.palette.secondary.main,
    },
  },
  currentStep: {
    '& button': {
      background: theme.palette.secondary.dark,
    },
    // styling for step divider
    '& + div': {
      background: theme.palette.secondary.light,
    },
  },
}));

const SwmpStep: React.FC<SwmpStepProps> = ({
  swmpId,
  currentStep,
  totalSteps,
  reportId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { accessType } = useParams();

  let currentStepIndex = useMemo(
    () =>
      totalSteps.findIndex((step) => {
        return step.id === currentStep;
      }),
    [totalSteps, currentStep]
  );
  let steps: any[] = [];

  if (totalSteps.length === 2) {
    steps = totalSteps;
  } else if (currentStepIndex === 0) {
    steps = totalSteps.slice(0, 3);
    currentStepIndex = 0;
  } else if (currentStepIndex === totalSteps.length - 1) {
    steps = totalSteps.slice(totalSteps.length - 3, totalSteps.length);
    currentStepIndex = 2;
  } else {
    steps = [
      totalSteps[currentStepIndex - 1],
      totalSteps[currentStepIndex],
      totalSteps[currentStepIndex + 1],
    ];
    currentStepIndex = 1;
  }

  const handleMoveStep = (stepId: string) => {
    let route = '';

    if (reportId) {
      route = `/swmp/${swmpId}/${reportId}/annualReport/${camelCase(stepId)}`;
    } else if (accessType) {
      route = `/swmp/${swmpId}/${accessType}/${camelCase(stepId)}`;
    } else {
      route = `/swmp/draft/${swmpId}/${camelCase(stepId)}`;
    }

    history.push(route);
  };

  return (
    <Box
      flex="1"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={6}>
      {steps.map((step, index) => (
        <React.Fragment key={step.id}>
          <Box
            className={cx({
              [classes.doneStep]: index < currentStepIndex,
              [classes.currentStep]: index === currentStepIndex,
            })}
            display="flex"
            flex="1">
            <Button
              className={classes.stepButton}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => handleMoveStep(step.id)}>
              {step.text}
            </Button>
          </Box>
          {index !== steps.length - 1 && (
            <Box className={classes.stepDivider} />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default SwmpStep;
