export enum SWMPAnnulReportStep {
  GeneralInfo = 'GeneralInfo',
  ComplianceStatus = 'ComplianceStatus',
  StormwaterDataSummary = 'StormwaterDataSummary',
  ImpairedWaterBodies = 'ImpairedWaterBodies',
  StormwaterActivities = 'StormwaterActivities',
  StormwaterModifications = 'StormwaterModifications',
  AdditionalBmp = 'AdditionalBmp',
  AdditionalInformation = 'AdditionalInformation',
  ConstructionActivities = 'ConstructionActivities',
}
