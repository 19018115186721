import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

const observer = new MutationObserver(() => {
  const element = document.getElementById('root');

  if (element) {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      element
    );
  }
});

observer.observe(document, {
  childList: true,
  subtree: true,
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
