import { isEqual } from 'lodash';
import { SWMPStatus } from 'models';
import { INITIAL_SWMP, SWMP } from 'models/SWMP';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import { fetchSwmpDetails } from 'services';

import SwmpDraft from '../SwmpDraft';

interface SwmpAccessProps {
  swmp: SWMP;
  onLoad: (swmpId: number) => Promise<void>;
}

const SwmpAccess: React.FC<SwmpAccessProps> = ({ swmp, onLoad }) => {
  const [showBmpSection, setShowBmpSection] = useState(false);
  const { id: swmpId } = useParams();
  const history = useHistory();

  useEffect(() => {
    onLoad(swmpId);
  }, [swmpId, onLoad]);

  useEffect(() => {
    if (!swmp || isEqual(swmp, INITIAL_SWMP)) {
      return;
    }

    if (swmp.status === SWMPStatus.Draft) {
      return history.push(`/swmp/${swmp.id}`);
    }

    setShowBmpSection(true);
  }, [swmp, history]);

  if (!showBmpSection) {
    return null;
  }

  return <SwmpDraft />;
};

const mapStateToProps = (state) => ({
  swmp: select.swmp(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: async (swmpId: number) => {
    const { bmp, mg, swmp } = await fetchSwmpDetails(swmpId);

    dispatch(actions.updateSwmp(swmp));
    dispatch(actions.updateBmp(bmp));
    dispatch(actions.updateMg(mg));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SwmpAccess);
