import { TextareaAutosizeProps } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import React from 'react';

interface TextareaProps extends TextareaAutosizeProps {
  label?: string;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  textArea: {
    font: 'inherit',
    resize: 'vertical',
    width: '100%',
    minHeight: theme.spacing(25),
    outline: 0,
    border: 0,
    padding: '1em',

    '&:hover ~ div': {
      borderColor: '#000',
    },
    '&:focus ~ div': {
      borderWidth: 2,
      borderColor: theme.palette.primary.dark,
    },
  },
  textAreaContainer: {
    position: 'relative',
    display: 'flex',

    '& textarea:last-of-type': {
      display: 'none',
    },
  },
  textAreaBorder: {
    content: "''",
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    pointerEvents: 'none',
    borderRadius: 3,
  },
}));

const Textarea: React.FC<TextareaProps> = ({ label, disabled, ...rest }) => {
  const classes = useStyles();

  return (
    <>
      {label && (
        <Box mb={1}>
          <Typography
            variant="body1"
            color={disabled ? 'textSecondary' : 'textPrimary'}>
            {label}
          </Typography>
        </Box>
      )}
      <Box className={classes.textAreaContainer}>
        <TextareaAutosize className={classes.textArea} {...rest} />
        <Box className={classes.textAreaBorder} />
      </Box>
    </>
  );
};

export default Textarea;
