import { amber, green, red } from '@material-ui/core/colors';
import SvgIcon from '@material-ui/core/SvgIcon';
import ActiveIcon from '@material-ui/icons/Check';
import DraftIcon from '@material-ui/icons/CreateOutlined';
import PreviousIcon from '@material-ui/icons/LockOutlined';
import { SWMPStatus } from 'models';

export interface SWMPStatusDisplay {
  status: SWMPStatus;
  color: string;
  Icon: typeof SvgIcon;
}

const statusDisplayMap: { [key: string]: SWMPStatusDisplay } = {
  [SWMPStatus.Draft]: {
    status: SWMPStatus.Draft,
    color: amber[100],
    Icon: DraftIcon,
  },
  [SWMPStatus.Active]: {
    status: SWMPStatus.Active,
    color: green[100],
    Icon: ActiveIcon,
  },
  [SWMPStatus.Previous]: {
    status: SWMPStatus.Previous,
    color: red[100],
    Icon: PreviousIcon,
  },
};

/** Provide display properties (ie. icon, color) for a SWMPStatus */
export const useSwmpStatusDisplay = (
  swmpStatus: SWMPStatus
): SWMPStatusDisplay => {
  const display = statusDisplayMap[swmpStatus];
  if (!display) {
    throw new Error(
      `Display configuration for SWMPStatus '${swmpStatus}' not defined`
    );
  }
  return display;
};
