import { get } from 'lodash';

import { AppState } from './types';

const selector = (path: string) => (state: AppState) => get(state, path);

const select = {
  customer: selector('customer'),
  swmpList: selector('swmpList'),
  swmp: selector('swmp'),
  planLength: selector('swmp.planLength'),
  bstMgtPracs: selector('bstMgtPracs'),
  measurableGoals: selector('measurableGoals'),
  activities: selector('activities'),
  activityAttachments: selector('activityAttachments'),
  annualReport: selector('annualReport'),
  annualReportYear: selector('annualReportYear'),
  annualReportContact: selector('annualReportContact'),
  error: selector('error'),
  loading: selector('loading'),
};

export default select;
