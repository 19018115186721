import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import PhoneOutlined from '@material-ui/icons/PhoneOutlined';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { TextField } from 'components';
import { AnnualReportContact } from 'models/AnnualReportContact';
import { SWMP } from 'models/SWMP';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import { updateAnnualReportContact } from 'services';
import { getStepRoute } from 'utils';

import { SwmpAnnualReportPromptProps } from '.';

interface SwmpGeneralInfoProps extends SwmpAnnualReportPromptProps {
  swmp: SWMP;
  annualReportContact: AnnualReportContact;
  onUpdate: (annualReportContact: AnnualReportContact) => void;
}

export const useStyles = makeStyles({
  datePicker: {
    flex: 1,
  },
  buttonIcon: {
    color: '#FFF',
  },
  error: {
    color: '#F00',
  },
});

const SwmpGeneralInfo: React.FC<SwmpGeneralInfoProps> = ({
  swmp,
  annualReport,
  annualReportYear,
  annualReportContact,
  onUpdateAnnualReport,
  onUpdate,
}) => {
  const { general } = annualReport;
  const classes = useStyles();
  const history = useHistory();
  const { swmpId, step, reportId } = useParams();

  const reportingYear = useMemo(() => {
    return annualReportYear - parseInt(swmp.year) + 1;
  }, [annualReportYear, swmp.year]);

  const initialValues = useMemo(
    () => ({
      id: annualReportContact.id,
      contactName: annualReportContact?.contactName || swmp.contactName || '',
      phone: annualReportContact?.phone || swmp.phone || '',
      emailAddress:
        annualReportContact?.emailAddress || swmp.emailAddress || '',
      mailingAddress:
        annualReportContact?.mailingAddress || swmp.mailingAddress || '',
    }),
    [annualReportContact, swmp]
  );

  const [formFields, setFormFields] = useState(initialValues);

  const originalFormFieldsRef = useRef(initialValues);

  useEffect(() => {
    setFormFields(initialValues);
    originalFormFieldsRef.current = initialValues;
  }, [initialValues]);

  const handleChangeGeneralInfo = (event) => {
    const { name, value } = event.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleBlurGeneralInfo = () => {
    if (
      JSON.stringify(formFields) !==
      JSON.stringify(originalFormFieldsRef.current)
    ) {
      onUpdate(formFields);
      originalFormFieldsRef.current = formFields;
    }
  };

  return (
    <>
      <TextField
        label="Authorization Number"
        placeholder="Authorization Number"
        fullWidth
        value={swmp.number || ''}
        disabled
      />
      <TextField
        label="Reporting Year"
        placeholder="Reporting Year"
        fullWidth
        value={annualReportYear ? reportingYear.toString() : ''}
        disabled
      />
      {swmp.year && !swmp.annualReportingYear && (
        <TextField
          label="Calendar Year"
          fullWidth
          value={`01/01/${new Date(
            parseInt(swmp.year) + reportingYear - 1,
            0,
            1
          ).getFullYear()}`}
          disabled
        />
      )}
      {swmp.annualReportingYear && (
        <>
          <TextField
            label="Fiscal Year"
            fullWidth
            value={
              new Date(swmp.annualReportingYear).getFullYear() +
              reportingYear -
              1
            }
            disabled
          />
          <Box mb={3}>
            <Box mb={1}>
              <Typography variant="body1" color="textSecondary">
                Last day of fiscal year
              </Typography>
            </Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disabled
                disableToolbar
                fullWidth
                variant="inline"
                format="MM/dd"
                placeholder="mm/dd"
                id="date-picker-inline"
                value={swmp.annualReportingYear}
                className={classes.datePicker}
                inputVariant="outlined"
                onChange={() => {}}
                InputAdornmentProps={{ position: 'start' }}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </>
      )}
      <TextField
        label="Name of MS4"
        placeholder="Organization name"
        fullWidth
        value={swmp.name || ''}
        disabled
      />
      <Box mb={3}>
        <Box mb={1}>
          <Typography variant="body1">Operator Level</Typography>
        </Box>
        <FormControl fullWidth variant="outlined">
          <Select
            disabled
            placeholder="MS4 operator level"
            value={swmp.operatorLevel || ''}>
            {Array.from({ length: 4 }).map((_, level) => (
              <MenuItem key={level} value={level + 1}>
                <Typography color="textSecondary">{level + 1}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <TextField
        label="Contact Name"
        name="contactName"
        placeholder="Primary contact for the MS4 permit"
        fullWidth
        value={formFields.contactName}
        onBlur={handleBlurGeneralInfo}
        onChange={handleChangeGeneralInfo}
      />
      <Box display="flex" justifyContent="space-between">
        <Box flex="1" mr={3}>
          <TextField
            label="Telephone Number"
            placeholder="(555) 555-5555"
            fullWidth
            name="phone"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneOutlined fontSize="small" />
                </InputAdornment>
              ),
            }}
            value={formFields.phone}
            onBlur={handleBlurGeneralInfo}
            onChange={handleChangeGeneralInfo}
          />
        </Box>
        <Box flex="1">
          <TextField
            label="Email Address"
            name="emailAddress"
            placeholder="admin@city.org"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined fontSize="small" />
                </InputAdornment>
              ),
            }}
            value={formFields.emailAddress}
            onBlur={handleBlurGeneralInfo}
            onChange={handleChangeGeneralInfo}
          />
        </Box>
      </Box>
      <TextField
        label="Mailing Address"
        name="mailingAddress"
        placeholder="Street Address, City, State Zipcode"
        fullWidth
        value={formFields.mailingAddress}
        onBlur={handleBlurGeneralInfo}
        onChange={handleChangeGeneralInfo}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}>
        <Box flexBasis="80%">
          <Typography>
            A copy of the annual report was submitted to the TCEQ Region
          </Typography>
        </Box>
        <RadioGroup
          value={general.isCopyReportSubmitted}
          onChange={(e) =>
            onUpdateAnnualReport(
              'isCopyReportSubmitted',
              e.target.value === 'true'
            )
          }>
          <FormControlLabel label="YES" value={true} control={<Radio />} />
          <FormControlLabel label="NO" value={false} control={<Radio />} />
        </RadioGroup>
      </Box>
      <Box mt={2}>
        <TextField
          label="Region the annual report was submitted to"
          placeholder="Annual report region"
          defaultValue={general.reportSubmittedRegion}
          onBlur={(e) =>
            onUpdateAnnualReport('reportSubmittedRegion', e.target.value)
          }
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography color="textSecondary">TCEQ Region</Typography>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box mt={6} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          endIcon={
            <ArrowForwardIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, true))
          }>
          Status of Compliance with the MS4 GP and SWMP
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  swmp: select.swmp(state),
  annualReport: select.annualReport(state),
  annualReportYear: select.annualReportYear(state),
  annualReportContact: select.annualReportContact(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAnnualReport: (path: string, value: any) =>
    dispatch(actions.updateAnnualReport(`general.${path}`, value)),
  onUpdate: (annualReportContact: AnnualReportContact) => {
    updateAnnualReportContact(annualReportContact);
    dispatch(actions.updateAnnualReportContact(annualReportContact));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SwmpGeneralInfo);
