import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/BlockOutlined';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import EditIcon from '@material-ui/icons/CreateOutlined';
import RemoveIcon from '@material-ui/icons/DeleteOutlined';
import { AddButton, LabelContainer, TextField, Textarea } from 'components';
import { useModal, useValidator } from 'hooks';
import { startCase } from 'lodash';
import { BMP, INITIAL_BMP } from 'models/BMP';
import { INITIAL_MG, MG } from 'models/MG';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as actions from 'redux/actions';

interface BmpCardProps {
  bmps: BMP[];
  planLength: number;
  showUpdateButton?: boolean;
  onEditBmp: (bmpId: string) => void;
  onRemoveBmp: (bmpId: string) => void;
  onCompleteMg: (measurableGoal: MG, isAdd: boolean, bmp: BMP) => void;
  onRemoveMg: (mgId: string, bmpId: string) => void;
  onSetError: (error: string) => void;
}

const useStyles = makeStyles((theme) => ({
  divider: {
    marginRight: -theme.spacing(2),
    marginLeft: -theme.spacing(2),
  },
  error: {
    color: '#F00',
  },
  description: {
    whiteSpace: 'pre-wrap',
  },
}));

const BmpCard: React.FC<BmpCardProps> = ({
  bmps,
  planLength,
  showUpdateButton = true,
  onEditBmp,
  onRemoveBmp,
  onCompleteMg,
  onRemoveMg,
  onSetError,
}) => {
  const [measurableGoal, setMeasurableGoal] = useState<MG>(INITIAL_MG);
  const [selectedMgBmp, setSelectedMgBmp] = useState<BMP>(INITIAL_BMP);
  const [isAddModal, setIsAddModal] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { Modal, title } = useModal(isAddModal, false);
  const classes = useStyles();

  const initializeMgModal = () => {
    setIsModalOpen(false);
    setIsAddModal(true);
    setMeasurableGoal(INITIAL_MG);
  };

  const handleCompleteMg = async () => {
    try {
      await onCompleteMg(measurableGoal, isAddModal, selectedMgBmp);
      initializeMgModal();
    } catch (error) {
      onSetError(error.response.data.message);
    }
  };

  const { validator, handleChange, handleComplete } = useValidator(
    measurableGoal,
    ['number', 'title', 'yearsImplemented'],
    isModalOpen,
    setMeasurableGoal,
    handleCompleteMg
  );

  return (
    <>
      {bmps.map((bmp) => (
        <Box key={bmp.id} mt={3}>
          <Card>
            <CardContent>
              <Box
                mb={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Typography variant="h6">
                  {bmp.number} {bmp.title}
                </Typography>
                {showUpdateButton && (
                  <Box display="flex">
                    <IconButton onClick={() => onEditBmp(bmp.id)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton onClick={() => onRemoveBmp(bmp.id)}>
                      <RemoveIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Box mb={2}>
                <Typography variant="body1" color="textPrimary">
                  {`MCMs: ${bmp.minimumControlMeasures.sort().join(', ')}`}
                  &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
                  {`Year Implemented: ${bmp.startYear ? bmp.startYear : 1}`}
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography
                  className={classes.description}
                  variant="body1"
                  color="textSecondary">
                  {bmp.description}
                </Typography>
              </Box>
              {showUpdateButton && (
                <AddButton
                  fullWidth
                  textVariant="h6"
                  text="Add Measurable Goal"
                  onClick={() => {
                    setSelectedMgBmp(bmp);
                    setMeasurableGoal({
                      ...measurableGoal,
                      number: bmp.number,
                      title: bmp.title,
                    });
                    setIsModalOpen(true);
                  }}
                />
              )}
              {bmp.measurableGoals.length > 0 ? (
                <>
                  <Box mt={3}>
                    <Typography variant="h6">Measurable Goals</Typography>
                  </Box>
                  {bmp.measurableGoals.map((mg) => (
                    <Box key={mg.id} mt={2}>
                      <Divider
                        className={classes.divider}
                        variant="fullWidth"
                      />
                      <Box
                        display="flex"
                        mt={2}
                        justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="body1">
                          {mg.number} {mg.title}
                        </Typography>
                        {showUpdateButton && (
                          <Box display="flex">
                            <IconButton
                              onClick={() => {
                                setMeasurableGoal(mg);
                                setSelectedMgBmp(bmp);
                                setIsAddModal(false);
                                setIsModalOpen(true);
                              }}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={() => onRemoveMg(mg.id, bmp.id)}>
                              <RemoveIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                      <Box mb={1}>
                        <Typography variant="body1">
                          Years Implemented:{' '}
                          {mg.yearsImplemented.sort().join(', ')}
                        </Typography>
                      </Box>
                      <Box display="flex" mb={1}>
                        {!mg.isQuantityRequired && (
                          <>
                            <Box mr={1}>
                              <BlockIcon fontSize="small" />
                            </Box>
                            <Typography variant="body1">
                              Quantity Not Required
                            </Typography>
                          </>
                        )}
                        {mg.isQuantityRequired && (
                          <>
                            {['informationUsed', 'units', 'annualGoal'].map(
                              (fieldKey) => (
                                <React.Fragment key={fieldKey}>
                                  <Typography>
                                    {startCase(fieldKey)}: {mg[fieldKey]}
                                    &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
                                  </Typography>
                                </React.Fragment>
                              )
                            )}
                            <Box mr={1}>
                              <CheckIcon fontSize="small" />
                            </Box>
                            <Typography variant="body1">
                              Quantity Required
                            </Typography>
                          </>
                        )}
                      </Box>
                      <Typography
                        className={classes.description}
                        variant="body1"
                        color="textSecondary">
                        {mg.description}
                      </Typography>
                    </Box>
                  ))}
                </>
              ) : (
                <Box mt={3} mb={2} textAlign="center">
                  <Typography className={classes.error} variant="body1">
                    Add at least 1 Measurable Goal to track activity for this
                    Best Management Practice
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      ))}

      <Modal
        open={isModalOpen}
        title={title}
        onComplete={handleComplete}
        onCancel={initializeMgModal}>
        <Box display="flex" justifyContent="space-between">
          <Box flexBasis="25%" mr={4}>
            <TextField
              fullWidth
              label="Number"
              name="number"
              value={measurableGoal.number}
              placeholder="1.1.1"
              onChange={handleChange}
              helperText={
                validator['number'] === false ? 'Number is required' : ''
              }
            />
          </Box>
          <Box flex="1">
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={measurableGoal.title}
              placeholder="Brief title describing the measurable goal"
              onChange={handleChange}
              helperText={
                validator['title'] === false ? 'Title is required' : ''
              }
            />
          </Box>
        </Box>
        <LabelContainer label="Years Implemented">
          {Array.from({ length: planLength }).map((_, index) => (
            <Box display="inline" key={index} mr={2}>
              <FormControlLabel
                label={(index + 1).toString()}
                name="yearsImplemented"
                value={index + 1}
                control={
                  <Checkbox
                    disabled={index <= selectedMgBmp.startYear - 2}
                    indeterminate={index <= selectedMgBmp.startYear - 2}
                    checked={measurableGoal.yearsImplemented.includes(
                      index + 1
                    )}
                    onChange={handleChange}
                  />
                }
              />
            </Box>
          ))}
          <Typography className={classes.error} variant="body2">
            {validator['yearsImplemented'] === false ? 'Year is required' : ''}
          </Typography>
        </LabelContainer>
        <Box mt={1} mb={1}>
          <FormControlLabel
            name="isQuantityRequired"
            control={
              <Checkbox
                checked={measurableGoal.isQuantityRequired}
                onChange={handleChange}
              />
            }
            label="Allow tracking quantities with activities"
          />
        </Box>
        <Box display="flex">
          <Box mr={3}>
            <TextField
              fullWidth
              highlightLabel={false}
              label="Information Used"
              name="informationUsed"
              value={measurableGoal.informationUsed}
              placeholder="Goal summary"
              disabled={!measurableGoal.isQuantityRequired}
              onChange={handleChange}
            />
          </Box>
          <Box mr={3}>
            <TextField
              fullWidth
              highlightLabel={false}
              label="Units"
              name="units"
              value={measurableGoal.units}
              placeholder="Brochures"
              disabled={!measurableGoal.isQuantityRequired}
              onChange={handleChange}
            />
          </Box>
          <TextField
            fullWidth
            highlightLabel={false}
            label="Annual Goal"
            name="annualGoal"
            value={measurableGoal.annualGoal}
            placeholder="5"
            disabled={!measurableGoal.isQuantityRequired}
            onChange={handleChange}
          />
        </Box>
        <Textarea
          label="Description"
          name="description"
          value={measurableGoal.description}
          placeholder="Provide a full description of the measurable goal"
          onChange={handleChange}
        />
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onSetError: (error: string) => dispatch(actions.setError(error)),
});

export default connect(null, mapDispatchToProps)(BmpCard);
