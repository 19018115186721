import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';
import { LabelContainer, TextField } from 'components';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import { getStepRoute, removeAnnualReportEntity } from 'utils';
import * as uuid from 'uuid';

import { useStyles } from './SwmpComplianceStatus';
import { SwmpAnnualReportPromptProps } from '.';

const SwmpAdditionalBmp: React.FC<SwmpAnnualReportPromptProps> = ({
  annualReport,
  onUpdateAnnualReport,
}) => {
  const { additionalBmps } = annualReport;
  const classes = useStyles();
  const history = useHistory();
  const { swmpId, step, reportId } = useParams();
  const [newBmp, setNewBmp] = useState<string>('');

  return (
    <>
      <LabelContainer label="Provide a description and schedule for implementation of additional BMPs that may be necessary, based on monitoring results, to ensure compliance with applicable TMDLs and implementation plans.">
        <Box display="flex" justifyContent="space-between">
          <Box flexBasis="85%">
            <TextField
              noMb
              fullWidth
              placeholder="Add a new BMP"
              onChange={(e) => setNewBmp(e.target.value)}
            />
          </Box>
          <Button
            className={classes.addButton}
            variant="contained"
            color="secondary"
            endIcon={
              <AddIcon className={classes.buttonIcon} fontSize="small" />
            }
            onClick={() => {
              if (!newBmp) {
                return;
              }
              const index = additionalBmps.additionalBmpsImplementations.length;

              onUpdateAnnualReport(`additionalBmpsImplementations.${index}`, {
                id: uuid.v4(),
                bmp: newBmp,
                description: '',
                implementationSchedule: '',
                status: '',
              });
            }}>
            Add
          </Button>
        </Box>
      </LabelContainer>

      {additionalBmps.additionalBmpsImplementations.map((b, index) => (
        <Box key={b.id}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={1}>
            <Box flexBasis="85%" flexWrap="wrap">
              <Typography variant="body1">BMP: {b.bmp}</Typography>
            </Box>
            <IconButton
              onClick={() =>
                removeAnnualReportEntity(
                  'additionalBmpsImplementations',
                  index,
                  additionalBmps,
                  onUpdateAnnualReport
                )
              }>
              <DeleteIcon color="secondary" />
            </IconButton>
          </Box>

          <Box display="flex" justifyContent="space-between" pb={2}>
            <Box flexBasis="40%">
              <TextField
                noMb
                fullWidth
                label="Implementation Schedule (start date, etc.)"
                placeholder="Implementation Schedule (start date, etc.)"
                defaultValue={b.implementationSchedule}
                onBlur={(e) =>
                  onUpdateAnnualReport(
                    `additionalBmpsImplementations.${index}.implementationSchedule`,
                    e.target.value
                  )
                }
              />
            </Box>

            <Box flexBasis="55%">
              <TextField
                noMb
                fullWidth
                label="Status/Completion Date (completed, in progress, not started)"
                placeholder="Status/Completion Date (completed, in progress, not started)"
                defaultValue={b.status}
                onBlur={(e) =>
                  onUpdateAnnualReport(
                    `additionalBmpsImplementations.${index}.status`,
                    e.target.value
                  )
                }
              />
            </Box>
          </Box>
          <TextField
            noMb
            fullWidth
            label="Implemented or Proposed Changes (Submit NOC as needed)"
            placeholder="Implemented or Proposed Changes (Submit NOC as needed)"
            multiline
            rows={5}
            defaultValue={b.description}
            onBlur={(e) =>
              onUpdateAnnualReport(
                `additionalBmpsImplementations.${index}.description`,
                e.target.value
              )
            }
          />
        </Box>
      ))}

      <Box display="flex" justifyContent="space-between" mt={6}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            <ArrowBackIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, false))
          }>
          SWMP Modifications
        </Button>
        <Button
          variant="contained"
          color="secondary"
          endIcon={
            <ArrowForwardIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, true))
          }>
          Additional Information
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  bstMgtPracs: select.bstMgtPracs(state),
  annualReport: select.annualReport(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAnnualReport: (path: string, value: any) =>
    dispatch(actions.updateAnnualReport(`additionalBmps.${path}`, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwmpAdditionalBmp);
