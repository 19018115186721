export const getDifferentEntities = (
  primaryEntities: any[],
  filteringEntities: any,
  entityName: string,
  isExactMatch?: boolean
) => {
  return primaryEntities.reduce((acc, p) => {
    const isExist = filteringEntities.some((f) => {
      if (!isExactMatch) {
        return f[entityName].id === p.id;
      }

      return f[entityName] === p.id;
    });

    if (!isExist) {
      acc.push(p);
    }

    return acc;
  }, [] as any[]);
};
