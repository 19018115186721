import { Activity } from 'models/Activity';
import { AnnualReport } from 'models/AnnualReport';
import { AnnualReportContact } from 'models/AnnualReportContact';
import { BMP } from 'models/BMP';
import { Customer } from 'models/Customer';
import { MG } from 'models/MG';
import { Ms4File } from 'models/Ms4File';
import { SWMP } from 'models/SWMP';

import { ActionTypes, Actions } from './types';

export const setSwmpList = (swmpList: SWMP[]): Actions => ({
  type: ActionTypes.SET_SWMP_LIST,
  payload: swmpList,
});

export const setCustomer = (customer: Customer): Actions => ({
  type: ActionTypes.SET_CUSTOMER,
  payload: customer,
});

export const setAnnualReportYear = (year: number): Actions => ({
  type: ActionTypes.SET_ANNUAL_REPORT_YEAR,
  payload: year,
});

export const setAnnualReport = (report: AnnualReport): Actions => ({
  type: ActionTypes.SET_ANNUAL_REPORT,
  payload: report,
});

export const setAnnualReportContact = (
  contact: AnnualReportContact
): Actions => ({
  type: ActionTypes.SET_ANNUAL_REPORT_CONTACT,
  payload: contact,
});

export const updateAnnualReportContact = (
  annualReportContact: AnnualReportContact
): Actions => ({
  type: ActionTypes.UPDATE_ANNUAL_REPORT_CONTACT,
  payload: annualReportContact,
});

export const updateSwmp = (swmp: SWMP): Actions => ({
  type: ActionTypes.UPDATE_SWMP,
  payload: swmp,
});

export const updateBmp = (bmp: BMP[]): Actions => ({
  type: ActionTypes.UPDATE_BMP,
  payload: bmp,
});

export const updateMg = (mg: MG[]): Actions => ({
  type: ActionTypes.UPDATE_MG,
  payload: mg,
});

export const updateActivity = (activities: Activity[]): Actions => ({
  type: ActionTypes.UPDATE_ACTIVITY,
  payload: activities,
});

export const setActivityAttachment = (
  attachments: Record<string, Ms4File[]>
): Actions => ({
  type: ActionTypes.SET_ACTIVITY_ATTACHMENT,
  payload: attachments,
});

export const updateAnnualReport = (path: string, value: any): Actions => ({
  type: ActionTypes.UPDATE_ANNUAL_REPORT,
  payload: { path, value },
});

export const setError = (error: string): Actions => ({
  type: ActionTypes.SET_ERROR,
  payload: error,
});

export const clearError = (): Actions => ({
  type: ActionTypes.CLEAR_ERROR,
});

export const setLoading = (isLoading: boolean): Actions => ({
  type: ActionTypes.SET_LOADING,
  payload: isLoading,
});
