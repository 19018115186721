import { PermitConditions } from './PermitConditions';
import { SWMPAddStep } from './SWMPAddStep';
import { SWMPAnnulReportStep } from './SWMPAnnulReportStep';

export interface SWMPAddStepInfo {
  id: SWMPAddStep;
  text: string;
}

export const SWMP_ADD_STEPS: SWMPAddStepInfo[] = [
  {
    id: SWMPAddStep.General,
    text: 'General Information',
  },
  {
    id: SWMPAddStep.Bmp,
    text: 'Best Management Practices',
  },
  {
    id: SWMPAddStep.Start,
    text: 'Start SWMP',
  },
];

export interface SWMPAnnualReportStepInfo {
  id: SWMPAnnulReportStep;
  text: string;
}

export const SWMP_ANNUAL_REPORT_STEPS: SWMPAnnualReportStepInfo[] = [
  {
    id: SWMPAnnulReportStep.GeneralInfo,
    text: 'A. General Information',
  },
  {
    id: SWMPAnnulReportStep.ComplianceStatus,
    text: 'B. Status of Compliance',
  },
  {
    id: SWMPAnnulReportStep.StormwaterDataSummary,
    text: 'C. Stormwater Data Summary',
  },
  {
    id: SWMPAnnulReportStep.ImpairedWaterBodies,
    text: 'D. Impaired Waterbodies',
  },
  {
    id: SWMPAnnulReportStep.StormwaterActivities,
    text: 'E. Stormwater Activities',
  },
  {
    id: SWMPAnnulReportStep.StormwaterModifications,
    text: 'F. SWMP Modifications',
  },
  {
    id: SWMPAnnulReportStep.AdditionalBmp,
    text: 'G. Additional BMPs',
  },
  {
    id: SWMPAnnulReportStep.AdditionalInformation,
    text: 'H. Additional Information',
  },
  {
    id: SWMPAnnulReportStep.ConstructionActivities,
    text: 'I. Construction Activities',
  },
];

export const COMPLIANCE_STATUS: {
  permitCondition: PermitConditions;
  text: string;
}[] = [
  {
    permitCondition: PermitConditions.SubmittedSwmp,
    text:
      'Permittee is currently in compliance with the SWMP as submitted to and approved by the TCEQ.',
  },
  {
    permitCondition: PermitConditions.RecordKeepingRequirements,
    text:
      'Permittee is currently in compliance with recordkeeping and reporting requirements.',
  },
  {
    permitCondition: PermitConditions.EligibilityRequirements,
    text:
      'Permittee meets the eligibility requirements of the permit (e.g., TMDL requirements, Edwards Aquifer limitations, compliance history, etc.).',
  },
  {
    permitCondition: PermitConditions.ConductedAnnualReview,
    text:
      'Permittee conducted an annual review of its SWMP in conjunction with preparation of the annual report',
  },
];
