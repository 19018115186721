import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as D from 'date-fns';
import { useSwmpStatusDisplay } from 'hooks';
import { SWMPStatus } from 'models';
import { SWMP } from 'models/SWMP';
import React from 'react';
import theme from 'theme';

interface SwmpCardProps {
  swmp: SWMP;
}

const useStyles = makeStyles({
  boxContainer: {
    cursor: 'pointer',
  },
});

const SwmpCard: React.FC<SwmpCardProps> = ({ swmp }) => {
  const { color, Icon } = useSwmpStatusDisplay(swmp.status);
  const classes = useStyles();
  const content = swmp.number ? [`${swmp.number}`] : [];

  switch (swmp.status) {
    case SWMPStatus.Draft:
      content.push(
        `Starting ${
          swmp.annualReportingYear
            ? `${D.format(new Date(swmp.annualReportingYear), 'MMMM d, ')} ${
                swmp.year
              }`
            : `January 1, ${swmp.year}`
        }`
      );
      break;

    case SWMPStatus.Active:
      content.push(
        `Currently in Year ${
          new Date().getFullYear() - parseInt(swmp.year) + 1
        }`
      );

      if (swmp.updatedDate) {
        content.push(
          `Updated ${D.format(new Date(swmp.updatedDate), 'MMMM d, yyyy')}`
        );
      }
      break;

    case SWMPStatus.Previous:
      content.push(
        `Completed ${
          swmp.annualReportingYear
            ? `${D.format(new Date(swmp.annualReportingYear), 'MMMM d,')} ${
                parseInt(swmp.year) + swmp.planLength
              }`
            : `December 31, ${parseInt(swmp.year) + swmp.planLength}`
        }`
      );
      break;

    default:
      break;
  }

  return (
    <Box
      className={classes.boxContainer}
      borderRadius={3}
      boxShadow={3}
      display="flex"
      mt={3}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flexBasis={theme.spacing(12)}
        bgcolor={color}
        py={3}>
        <Box display="flex" mb={0.625}>
          <Icon />
        </Box>
        <Typography variant="body2" color="textSecondary">
          {swmp.status}
        </Typography>
      </Box>
      <Box flex="1" px={3} py={2.25} alignItems="space-between" flexWrap="wrap">
        <Typography variant="subtitle1">{swmp.year}</Typography>
        <Typography variant="body1" color="textSecondary">
          {content.join(' • ')}
        </Typography>
      </Box>
    </Box>
  );
};

export default SwmpCard;
