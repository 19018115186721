import { camelCase, upperFirst } from 'lodash';
import { SWMPAddStep, SWMPAnnulReportStep } from 'models';

export const getStepRoute = (
  swmpId: number,
  reportId: number | null,
  currentStep: string,
  isForward: boolean,
  swmpAccessType?: string
) => {
  let step = '';
  const steps = Object.keys(reportId ? SWMPAnnulReportStep : SWMPAddStep);

  const currentIndex = steps.findIndex(
    (step) => step === upperFirst(currentStep)
  );

  if (isForward) {
    step = camelCase(steps[currentIndex + 1]);
  } else {
    step = camelCase(steps[currentIndex - 1]);
  }

  window.scrollTo(0, 0);

  if (reportId) {
    return `/swmp/${swmpId}/${reportId}/annualReport/${step}`;
  } else if (swmpAccessType) {
    return `/swmp/${swmpId}/${swmpAccessType}/${camelCase(step)}`;
  }

  return `/swmp/draft/${swmpId}/${step}`;
};
