import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { SwmpStep } from 'components';
import { upperFirst } from 'lodash';
import { SWMPAnnulReportStep } from 'models';
import { AnnualReport } from 'models/AnnualReport';
import { SWMP_ANNUAL_REPORT_STEPS } from 'models/constants';
import { SWMP } from 'models/SWMP';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import {
  downloadAnnualReport,
  fetchAnnualReport,
  fetchSwmpDetails,
  updateAnnualReport,
} from 'services';
import { downloadFile } from 'utils';

import SwmpActivities from './SwmpActivities';
import SwmpAdditionalBmp from './SwmpAdditionalBmp';
import SwmpAdditionalInformation from './SwmpAdditionalInformation';
import SwmpComplianceStatus from './SwmpComplianceStatus';
import SwmpConstructionActivities from './SwmpConstructionActivities';
import SwmpDataSummary from './SwmpDataSummary';
import SwmpGeneralInfo from './SwmpGeneralInfo';
import SwmpImpairedWaterBodies from './SwmpImpairedWaterBodies';
import SwmpModifications from './SwmpModifications';

export interface SwmpAnnualReportPromptProps {
  annualReport: AnnualReport;
  annualReportYear: number;
  onUpdateAnnualReport: (path: string, value: any) => void;
}

interface SwmpAnnualReportProps {
  annualReport: AnnualReport;
  annualReportYear: number;
  swmp: SWMP;
  onLoad: (swmpId: number, reportId: number) => Promise<void>;
}

const SwmpAnnualReport: React.FC<SwmpAnnualReportProps> = ({
  annualReport,
  annualReportYear,
  swmp,
  onLoad,
}) => {
  const [isLoadingStates, setIsLoadingStates] = useState(true);

  const initialLoad = useRef(true);
  const { swmpId, step, reportId } = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchEntities = async () => {
      await onLoad(swmpId, reportId);
      // to re-render the components when the annual report data is fully loaded
      // because of we are setting defaultValue to the input components
      setIsLoadingStates(false);
      initialLoad.current = false;
    };

    fetchEntities();
  }, [onLoad, swmpId, reportId]);

  useEffect(() => {
    if (initialLoad.current) return;

    updateAnnualReport(reportId, annualReport);
  }, [annualReport, reportId]);

  let StepContainer: React.FC<any>;

  switch (upperFirst(step)) {
    case SWMPAnnulReportStep.ComplianceStatus:
      StepContainer = SwmpComplianceStatus;
      break;

    case SWMPAnnulReportStep.StormwaterDataSummary:
      StepContainer = SwmpDataSummary;
      break;

    case SWMPAnnulReportStep.ImpairedWaterBodies:
      StepContainer = SwmpImpairedWaterBodies;
      break;

    case SWMPAnnulReportStep.StormwaterActivities:
      StepContainer = SwmpActivities;
      break;

    case SWMPAnnulReportStep.StormwaterModifications:
      StepContainer = SwmpModifications;
      break;

    case SWMPAnnulReportStep.AdditionalBmp:
      StepContainer = SwmpAdditionalBmp;
      break;

    case SWMPAnnulReportStep.AdditionalInformation:
      StepContainer = SwmpAdditionalInformation;
      break;

    case SWMPAnnulReportStep.ConstructionActivities:
      StepContainer = SwmpConstructionActivities;
      break;

    default:
      StepContainer = SwmpGeneralInfo;
      break;
  }

  const handleDownloadAnnualReport = async () => {
    const { fileName, fileUrl } = await downloadAnnualReport(reportId);
    downloadFile(fileName, fileUrl);
  };

  return (
    <React.Fragment key={isLoadingStates ? 'loading' : 'rendered'}>
      <Box display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<ArrowBackIcon color="secondary" fontSize="small" />}
          onClick={() => history.push(`/swmp/${swmpId}`)}>
          Stormwater Management Plan {swmp.year}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDownloadAnnualReport}>
          Preview Report
        </Button>
      </Box>
      <Box mt={6} mb={4}>
        <Typography variant="subtitle1" align="center">
          {swmp.year} TCEQ Annual Report (Year{' '}
          {annualReportYear ? annualReportYear - parseInt(swmp.year) + 1 : ''})
        </Typography>
      </Box>
      <SwmpStep
        swmpId={swmpId}
        reportId={reportId}
        currentStep={upperFirst(step)}
        totalSteps={SWMP_ANNUAL_REPORT_STEPS}
      />
      <StepContainer />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  annualReportYear: select.annualReportYear(state),
  annualReport: select.annualReport(state),
  swmp: select.swmp(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: async (swmpId: number, reportId: number) => {
    dispatch(actions.setLoading(true));

    const { swmpActivities, bmp, mg, swmp } = await fetchSwmpDetails(
      swmpId,
      reportId
    );
    const annualReport = await fetchAnnualReport(reportId);
    const {
      id,
      contactName,
      phone,
      emailAddress,
      mailingAddress,
    } = annualReport;
    const annualReportContact = {
      id,
      contactName,
      phone,
      emailAddress,
      mailingAddress,
    };
    dispatch(actions.setAnnualReportYear(annualReport.year));
    dispatch(actions.setAnnualReport(annualReport.report));
    dispatch(actions.setAnnualReportContact(annualReportContact));
    dispatch(actions.updateSwmp(swmp));
    dispatch(actions.updateBmp(bmp));
    dispatch(actions.updateMg(mg));
    dispatch(actions.updateActivity(swmpActivities));
    dispatch(actions.setLoading(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SwmpAnnualReport);
