import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { grey } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import PenIcon from '@material-ui/icons/CreateOutlined';
import DownloadIcon from '@material-ui/icons/GetAppOutlined';
import { Modal } from 'components';
import { SWMPStatus } from 'models';
import { Customer } from 'models/Customer';
import { INITIAL_SWMP, SWMP } from 'models/SWMP';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import {
  createAnnualReport,
  downloadAnnualReport,
  extendSwmp,
  fetchAnnualReportStatus,
  fetchSwmpActivityAttachments,
  fetchSwmpDetails,
} from 'services';
import { downloadFile } from 'utils';

import SwmpActivity from './SwmpActivity';

interface SwmpDetailProps {
  swmp: SWMP;
  customer: Customer;
  onLoad: (swmpId: number) => Promise<void>;
  onExtendSwmp: (swmp: SWMP) => Promise<void>;
  onReset: () => void;
}

interface IconLabelProps {
  label: string;
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    transition: 'all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)',
    '&:hover': {
      backgroundColor: grey[200],
    },
    height: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:last-child': {
      marginBottom: theme.spacing(2),
    },
  },
  modalContent: {
    padding: 0,
  },
  iconLabelWrapper: {
    cursor: 'pointer',
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

const IconLabel: React.FC<IconLabelProps> = ({ label, onClick }) => {
  const classes = useStyles();
  const Icon = label === 'Download' ? DownloadIcon : PenIcon;

  return (
    <Box
      className={classes.iconLabelWrapper}
      display="flex"
      alignItems="center"
      onClick={onClick}>
      <Icon className={classes.icon} fontSize="small" />
      <Box ml={1}>
        <Typography variant="body1" color="primary">
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

const SwmpDetail: React.FC<SwmpDetailProps> = ({
  swmp,
  customer,
  onExtendSwmp,
  onLoad,
  onReset,
}) => {
  const [annualReports, setAnnualReports] = useState<
    {
      id: number | null;
      year: number;
    }[]
  >([]);
  const [isOpenAnnualReportModal, setIsOpenAnnualReportModal] = useState<
    boolean
  >(false);
  const [isOpenExtendSwmpModal, setIsOpenExtendSwmpModal] = useState<boolean>(
    false
  );
  const { id: swmpId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    onLoad(swmpId);
  }, [swmpId, onLoad]);

  const handleAccessAnnualReport = (reportId: number | null) => {
    history.push(`${swmpId}/${reportId}/annualReport/generalInfo`);
  };

  const handleDownloadAnnualReport = async (reportId: number | null) => {
    const { fileName, fileUrl } = await downloadAnnualReport(
      reportId as number
    );
    downloadFile(fileName, fileUrl);
  };

  const handleCreateAnnualReport = async (year: number) => {
    const report = await createAnnualReport(swmpId, year);
    return handleAccessAnnualReport(report.id);
  };

  const handleExtendSwmp = async () => {
    swmp.planLength = swmp.planLength + 1;

    await onExtendSwmp(swmp);
    setIsOpenExtendSwmpModal(false);
  };

  const handleSelectAnnualReport = async () => {
    setIsOpenAnnualReportModal(true);
    setAnnualReports(await fetchAnnualReportStatus(swmpId));
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<ArrowBackIcon color="secondary" fontSize="small" />}
        onClick={() => {
          onReset();
          history.push('/swmp');
        }}>
        Stormwater Management Plans
      </Button>
      <Box mt={6}>
        <Typography variant="subtitle1" align="center">
          Stormwater Management Plan {swmp.year}
        </Typography>
        <Typography variant="h6" color="textSecondary" align="center">
          Currently in Year{' '}
          {swmp.year && new Date().getFullYear() - parseInt(swmp.year) + 1}
        </Typography>
        <Box mt={6}>
          {customer.admin && (
            <Box display="flex" justifyContent="center">
              {customer.annualReport && (
                <Box mr={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSelectAnnualReport}>
                    Annual Reports
                  </Button>
                </Box>
              )}
              {swmp.status === SWMPStatus.Active && (
                <>
                  <Box mr={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        history.push(`/swmp/${swmp.id}/update/general`)
                      }>
                      Update SWMP
                    </Button>
                  </Box>
                  <Box mr={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setIsOpenExtendSwmpModal(true)}>
                      {`Extend SWMP (Year ${swmp.planLength + 1})`}
                    </Button>
                  </Box>
                </>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.push(`/swmp/${swmp.id}/view/general`)}>
                View SWMP Details
              </Button>
            </Box>
          )}

          <Box mt={6}>
            <SwmpActivity />
          </Box>
        </Box>
      </Box>

      <Dialog
        open={isOpenAnnualReportModal}
        maxWidth="xs"
        fullWidth
        onClose={() => setIsOpenAnnualReportModal(false)}>
        <DialogTitle>Annual Reports</DialogTitle>
        <DialogContent className={classes.modalContent}>
          {annualReports.map((r, index) => (
            <Box
              key={index}
              className={classes.listItem}
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="h5">{r.year}</Typography>
              <Box display="flex">
                {r.id ? (
                  <>
                    <Box mr={2}>
                      <IconLabel
                        label="Edit"
                        onClick={() => handleAccessAnnualReport(r.id)}
                      />
                    </Box>
                    <IconLabel
                      label="Download"
                      onClick={() => handleDownloadAnnualReport(r.id)}
                    />
                  </>
                ) : (
                  <IconLabel
                    label="Create"
                    onClick={() => handleCreateAnnualReport(r.year)}
                  />
                )}
              </Box>
            </Box>
          ))}
        </DialogContent>
      </Dialog>

      <Modal
        open={isOpenExtendSwmpModal}
        title="Extend Stormwater Management Plan"
        completeActionText="Extend"
        onCancel={() => setIsOpenExtendSwmpModal(false)}
        onComplete={handleExtendSwmp}>
        <Box my={2}>
          <Typography align="center" variant="body1" color="textSecondary">
            Once started, all activity will start to be logged against this
            SWMP.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  swmp: select.swmp(state),
  customer: select.customer(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: async (swmpId: number) => {
    dispatch(actions.setLoading(true));

    const { swmpActivities, bmp, mg, customer, swmp } = await fetchSwmpDetails(
      swmpId
    );

    dispatch(actions.setCustomer(customer));
    dispatch(actions.updateSwmp(swmp));
    dispatch(actions.updateBmp(bmp));
    dispatch(actions.updateMg(mg));
    dispatch(actions.updateActivity(swmpActivities));

    const attachments = await fetchSwmpActivityAttachments(swmpId);

    dispatch(actions.setActivityAttachment(attachments));
    dispatch(actions.setLoading(false));
  },
  onExtendSwmp: async (swmp: SWMP) => {
    await extendSwmp(swmp);
    dispatch(actions.updateSwmp(swmp));
  },
  onReset: () => {
    dispatch(actions.updateSwmp(INITIAL_SWMP));
    dispatch(actions.updateBmp([]));
    dispatch(actions.updateMg([]));
    dispatch(actions.updateActivity([]));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SwmpDetail);
