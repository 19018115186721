import { MG } from './MG';

export interface BMP {
  id: string;
  swmpId?: number;
  number: string;
  title: string;
  description?: string;
  startYear: number;
  minimumControlMeasures: string[];
  measurableGoals: MG[];
}

export const INITIAL_BMP: BMP = {
  id: '',
  swmpId: 0,
  number: '',
  title: '',
  description: '',
  startYear: 0, // existing year as a default
  minimumControlMeasures: [],
  measurableGoals: [],
};
