import { SWMPStatus } from 'models';
import { SWMP } from 'models/SWMP';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchSwmpList } from 'services';

const SwmpActive: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    const fetchSwmps = async () => {
      const { swmp }: { swmp: SWMP[] } = await fetchSwmpList();
      const activatedSwmp = swmp.find((s) => s.status === SWMPStatus.Active);

      if (!activatedSwmp) {
        return history.push('/swmp');
      }

      history.push(`/swmp/${activatedSwmp.id}`);
    };

    fetchSwmps();
  }, [history]);

  return <></>;
};

export default SwmpActive;
