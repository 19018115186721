import { BoxProps } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

interface LabelContainerProps extends BoxProps {
  label?: string;
  disabled?: boolean;
}

const useStyles = makeStyles({
  label: {
    whiteSpace: 'pre-wrap',
    fontWeight: 500,
  },
});

const LabelContainer: React.FC<LabelContainerProps> = ({
  label,
  children,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box mt={5} {...rest}>
      <Box mb={2}>
        <Typography
          className={classes.label}
          variant="body1"
          color={disabled ? 'textSecondary' : 'textPrimary'}>
          {label}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

export default LabelContainer;
