import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';
import { LabelContainer, TextField } from 'components';
import { BMP, INITIAL_BMP } from 'models/BMP';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import {
  getDifferentEntities,
  getSortedEntities,
  getStepRoute,
  removeAnnualReportEntity,
} from 'utils';
import * as uuid from 'uuid';

import { useStyles } from './SwmpComplianceStatus';
import { SwmpAnnualReportPromptProps } from '.';

interface SwmpImpairedWaterBodiesProps extends SwmpAnnualReportPromptProps {
  bstMgtPracs: BMP[];
}

const SwmpImpairedWaterBodies: React.FC<SwmpImpairedWaterBodiesProps> = ({
  bstMgtPracs,
  annualReport,
  onUpdateAnnualReport,
}) => {
  const { impairedWaterBodes } = annualReport;
  const classes = useStyles();
  const history = useHistory();
  const { swmpId, step, reportId } = useParams();
  const [benchmarkParameter, setBenchmarkParameter] = useState<string>('');
  const [
    archievingBenchmarkParameterId,
    setArchievingBenchmarkParameterId,
  ] = useState<string>('');
  const [impairmentBacteriaBmpId, setImpairmentBacteriaBmpId] = useState<
    string
  >('');
  const [
    effectivenessBmpBenchmarkParameterId,
    setEffectivenessBmpBenchmarkParameterId,
  ] = useState<string>('');

  const impairmentBacteriaReports = getSortedEntities(
    impairedWaterBodes.impairmentBacteriaReports,
    'bmp'
  );

  return (
    <>
      <LabelContainer label="1. Identify whether an impaired water within the permitted area was added to the latest EPA-approved 303(d) list or the Texas Integrated Report of Surface Water Quality for CWA Sections 305(b) and 303(d). List any newly-identified impaired waters below by including the name of the water body and the cause of impairment.">
        <TextField
          fullWidth
          placeholder="Identify whether an impaired water within the permitted area"
          multiline
          rows={5}
          defaultValue={impairedWaterBodes.identifyImpairedWater}
          onBlur={(e) =>
            onUpdateAnnualReport('identifyImpairedWater', e.target.value)
          }
        />
      </LabelContainer>
      <LabelContainer label="2. If applicable, explain below any activities taken to address the discharge to impaired waterbodies, including any sampling results and a summary of the small MS4’s BMPs used to address the pollutant of concern.">
        <TextField
          fullWidth
          placeholder="Explain below any activities taken to address the discharge to impaired waterbodies"
          multiline
          rows={5}
          defaultValue={impairedWaterBodes.activitiesToDischarge}
          onBlur={(e) =>
            onUpdateAnnualReport('activitiesToDischarge', e.target.value)
          }
        />
      </LabelContainer>
      <LabelContainer label="3. Describe the implementation of targeted controls if the small MS4 discharges to an impaired water body with an approved TMDL.">
        <TextField
          fullWidth
          placeholder="Describe the implementation of targeted controls"
          multiline
          rows={5}
          defaultValue={impairedWaterBodes.implementationOfTargetedControls}
          onBlur={(e) =>
            onUpdateAnnualReport(
              'implementationOfTargetedControls',
              e.target.value
            )
          }
        />
      </LabelContainer>

      <LabelContainer label="4. Report the benchmark identified by the MS4 and assessment activities.">
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Box flexBasis="85%">
            <TextField
              noMb
              fullWidth
              placeholder="Benchmark Parameter"
              defaultValue={benchmarkParameter}
              onBlur={(e) => setBenchmarkParameter(e.target.value)}
            />
          </Box>

          <Button
            className={classes.addButton}
            variant="contained"
            color="secondary"
            endIcon={
              <AddIcon className={classes.buttonIcon} fontSize="small" />
            }
            onClick={() => {
              if (!benchmarkParameter) {
                return;
              }
              const index = impairedWaterBodes.identifiedBenchmarks.length;
              onUpdateAnnualReport(`identifiedBenchmarks.${index}`, {
                id: uuid.v4(),
                benchmarkParameter,
                benchmarkValue: '',
                yearsConducted: '',
                description: '',
              });
            }}>
            Add
          </Button>
        </Box>
      </LabelContainer>

      {impairedWaterBodes.identifiedBenchmarks.map((b, index) => (
        <Box key={b.id}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={1}>
            <Box flexBasis="85%">
              <Typography variant="body1">
                Benchmark Parameter: {b.benchmarkParameter}
                {b.benchmarkValue && ` • Benchmark Value: ${b.benchmarkValue}`}
                {b.yearsConducted &&
                  ` • Year(s) conducted: ${b.yearsConducted}`}
              </Typography>
            </Box>
            <IconButton
              onClick={() =>
                removeAnnualReportEntity(
                  'identifiedBenchmarks',
                  index,
                  impairedWaterBodes,
                  onUpdateAnnualReport
                )
              }>
              <DeleteIcon color="secondary" />
            </IconButton>
          </Box>

          <Box mb={2} display="flex" justifyContent="space-between">
            <Box flexBasis="45%">
              <TextField
                noMb
                fullWidth
                label="Benchmark Value"
                placeholder="Benchmark Value"
                defaultValue={b.benchmarkValue}
                onBlur={(e) =>
                  onUpdateAnnualReport(
                    `identifiedBenchmarks.${index}.benchmarkValue`,
                    e.target.value
                  )
                }
              />
            </Box>

            <Box flexBasis="45%">
              <TextField
                noMb
                fullWidth
                label="Year(s) conducted"
                placeholder="Year(s) conducted"
                defaultValue={b.yearsConducted}
                onBlur={(e) =>
                  onUpdateAnnualReport(
                    `identifiedBenchmarks.${index}.yearsConducted`,
                    e.target.value
                  )
                }
              />
            </Box>
          </Box>

          <TextField
            noMb
            fullWidth
            label="Description of additional sampling or other assessment activities"
            placeholder="Description of additional sampling or other assessment activities"
            multiline
            rows={5}
            defaultValue={b.description}
            onBlur={(e) =>
              onUpdateAnnualReport(
                `identifiedBenchmarks.${index}.description`,
                e.target.value
              )
            }
          />
        </Box>
      ))}

      <LabelContainer label="5. Provide an analysis of how the selected BMPs will be effective in contributing to achieving the benchmark.">
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Box flexBasis="85%">
            <FormControl fullWidth variant="outlined">
              <Select
                value={archievingBenchmarkParameterId}
                displayEmpty
                onChange={(e) =>
                  setArchievingBenchmarkParameterId(e.target.value as string)
                }>
                <MenuItem disabled value="">
                  <Typography color="textSecondary">
                    Select a Benchmark Parameter
                  </Typography>
                </MenuItem>
                {getDifferentEntities(
                  impairedWaterBodes.identifiedBenchmarks,
                  impairedWaterBodes.archievingBenchmarkAnalyses,
                  'benchmarkParameterId',
                  true
                ).map((b) => (
                  <MenuItem key={b.id} value={b.id}>
                    {b.benchmarkParameter}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Button
            className={classes.addButton}
            variant="contained"
            color="secondary"
            endIcon={
              <AddIcon className={classes.buttonIcon} fontSize="small" />
            }
            onClick={() => {
              if (!archievingBenchmarkParameterId) {
                return;
              }

              const index =
                impairedWaterBodes.archievingBenchmarkAnalyses.length;
              const benchmarkParameter = impairedWaterBodes.identifiedBenchmarks.find(
                (b) => b.id === archievingBenchmarkParameterId
              )?.benchmarkParameter;

              onUpdateAnnualReport(`archievingBenchmarkAnalyses.${index}`, {
                id: uuid.v4(),
                benchmarkParameterId: archievingBenchmarkParameterId,
                benchmarkParameter,
                bmp: INITIAL_BMP,
                description: '',
              });
              setArchievingBenchmarkParameterId('');
            }}>
            Add
          </Button>
        </Box>
      </LabelContainer>

      {impairedWaterBodes.archievingBenchmarkAnalyses.map((b, index) => (
        <Box key={b.id}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={1}>
            <Box flexBasis="85%">
              <Typography variant="body1">
                Benchmark Parameter: {b.benchmarkParameter}{' '}
                {b.bmp.id && ` • Selected BMP: ${b.bmp.number} ${b.bmp.title}`}
              </Typography>
            </Box>
            <IconButton
              onClick={() =>
                removeAnnualReportEntity(
                  'archievingBenchmarkAnalyses',
                  index,
                  impairedWaterBodes,
                  onUpdateAnnualReport
                )
              }>
              <DeleteIcon color="secondary" />
            </IconButton>
          </Box>
          <Box mb={2}>
            <Box mb={1}>
              <Typography variant="body1">Selected BMP</Typography>
            </Box>
            <FormControl fullWidth variant="outlined">
              <Select
                value={b.bmp?.id}
                name="mgId"
                displayEmpty
                onChange={(e) => {
                  const selectedBmp = bstMgtPracs.find(
                    (bmp) => bmp.id === e.target.value
                  );
                  onUpdateAnnualReport(
                    `archievingBenchmarkAnalyses.${index}.bmp`,
                    selectedBmp
                  );
                }}>
                <MenuItem disabled value={''}>
                  <Typography color="textSecondary">Select a BMP</Typography>
                </MenuItem>
                {bstMgtPracs.map((bmp) => (
                  <MenuItem key={bmp.id} value={bmp.id}>
                    <Typography color="textSecondary">
                      {bmp.number} {bmp.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <TextField
            fullWidth
            label="Contribution to achieving Benchmark"
            placeholder="Contribution to achieving Benchmark"
            multiline
            rows={5}
            defaultValue={b.description}
            onBlur={(e) =>
              onUpdateAnnualReport(
                `archievingBenchmarkAnalyses.${index}.description`,
                e.target.value
              )
            }
          />
        </Box>
      ))}

      <LabelContainer label="6. If applicable, report on focused BMPs to address impairment for bacteria.">
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Box flexBasis="85%">
            <FormControl fullWidth variant="outlined">
              <Select
                value={impairmentBacteriaBmpId}
                name="mgId"
                displayEmpty
                onChange={(e) =>
                  setImpairmentBacteriaBmpId(e.target.value as string)
                }>
                <MenuItem disabled value={''}>
                  <Typography color="textSecondary">Select a BMP</Typography>
                </MenuItem>
                {getDifferentEntities(
                  bstMgtPracs,
                  impairedWaterBodes.impairmentBacteriaReports,
                  'bmp'
                ).map((bmp) => (
                  <MenuItem key={bmp.id} value={bmp.id}>
                    <Typography color="textSecondary">
                      {bmp.number} {bmp.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Button
            className={classes.addButton}
            variant="contained"
            color="secondary"
            endIcon={
              <AddIcon className={classes.buttonIcon} fontSize="small" />
            }
            onClick={() => {
              if (!impairmentBacteriaBmpId) {
                return;
              }

              const selectedBmp = bstMgtPracs.find(
                (bmp) => bmp.id === impairmentBacteriaBmpId
              );
              const entities = [
                ...impairedWaterBodes.impairmentBacteriaReports,
                {
                  id: uuid.v4(),
                  bmp: selectedBmp,
                  description: '',
                },
              ];

              onUpdateAnnualReport('impairmentBacteriaReports', entities);
              setImpairmentBacteriaBmpId('');
            }}>
            Add
          </Button>
        </Box>
      </LabelContainer>

      {impairmentBacteriaReports.map((b, index) => (
        <Box key={b.id}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={1}>
            <Box flexBasis="85%" flexWrap="wrap">
              <Typography variant="body1">
                BMP: {b.bmp.number} {b.bmp.title}
              </Typography>
            </Box>
            <IconButton
              onClick={() =>
                removeAnnualReportEntity(
                  'impairmentBacteriaReports',
                  index,
                  impairedWaterBodes,
                  onUpdateAnnualReport
                )
              }>
              <DeleteIcon color="secondary" />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            label="Comments/Discussion"
            placeholder="Comments/Discussion"
            multiline
            rows={5}
            defaultValue={b.description}
            onBlur={(e) =>
              onUpdateAnnualReport(
                `impairmentBacteriaReports.${index}.description`,
                e.target.value
              )
            }
          />
        </Box>
      ))}

      <LabelContainer
        label={
          '7. Assess the progress to determine BMP’s effectiveness in achieving the benchmark.\n' +
          ' For example, the MS4 may use the following benchmark indicators:\n' +
          '   • number of sources identified or eliminated;\n' +
          '   • number of illegal dumpings;\n' +
          '   • increase in illegal dumping reported;\n' +
          '   • number of educational opportunities conducted;\n' +
          '   • reductions in sanitary sewer flows (SSOs);  /or\n' +
          '   • increase in illegal discharge detection through dry screening.'
        }>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Box flexBasis="85%">
            <FormControl fullWidth variant="outlined">
              <Select
                value={effectivenessBmpBenchmarkParameterId}
                displayEmpty
                onChange={(e) =>
                  setEffectivenessBmpBenchmarkParameterId(
                    e.target.value as string
                  )
                }>
                <MenuItem disabled value="">
                  <Typography color="textSecondary">
                    Select a Benchmark Parameter
                  </Typography>
                </MenuItem>
                {getDifferentEntities(
                  impairedWaterBodes.identifiedBenchmarks,
                  impairedWaterBodes.bmpEffectivenessProgresses,
                  'benchmarkParameterId',
                  true
                ).map((b) => (
                  <MenuItem key={b.id} value={b.id}>
                    {b.benchmarkParameter}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Button
            className={classes.addButton}
            variant="contained"
            color="secondary"
            endIcon={
              <AddIcon className={classes.buttonIcon} fontSize="small" />
            }
            onClick={() => {
              if (!effectivenessBmpBenchmarkParameterId) {
                return;
              }

              const index =
                impairedWaterBodes.bmpEffectivenessProgresses.length;
              const benchmarkParameter = impairedWaterBodes.identifiedBenchmarks.find(
                (b) => b.id === effectivenessBmpBenchmarkParameterId
              )?.benchmarkParameter;

              onUpdateAnnualReport(`bmpEffectivenessProgresses.${index}`, {
                id: uuid.v4(),
                benchmarkParameterId: effectivenessBmpBenchmarkParameterId,
                benchmarkParameter,
                description: '',
              });
              setEffectivenessBmpBenchmarkParameterId('');
            }}>
            Add
          </Button>
        </Box>
      </LabelContainer>

      {impairedWaterBodes.bmpEffectivenessProgresses.map((b, index) => (
        <Box key={b.id}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={1}>
            <Box flexBasis="85%">
              <Typography variant="body1">
                Benchmark Parameter: {b.benchmarkParameter}
              </Typography>
            </Box>
            <IconButton
              onClick={() =>
                removeAnnualReportEntity(
                  'bmpEffectivenessProgresses',
                  index,
                  impairedWaterBodes,
                  onUpdateAnnualReport
                )
              }>
              <DeleteIcon color="secondary" />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            label="Description/Comments"
            placeholder="Description/Comments"
            multiline
            rows={5}
            defaultValue={b.description}
            onBlur={(e) =>
              onUpdateAnnualReport(
                `bmpEffectivenessProgresses.${index}.description`,
                e.target.value
              )
            }
          />
        </Box>
      ))}

      <Box display="flex" justifyContent="space-between" mt={6}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            <ArrowBackIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, false))
          }>
          Stormwater Data Summary
        </Button>
        <Button
          variant="contained"
          color="secondary"
          endIcon={
            <ArrowForwardIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, true))
          }>
          Stormwater Activities
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  bstMgtPracs: select.bstMgtPracs(state),
  annualReport: select.annualReport(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAnnualReport: (path: string, value: any) =>
    dispatch(actions.updateAnnualReport(`impairedWaterBodes.${path}`, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwmpImpairedWaterBodies);
