import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import * as D from 'date-fns';
import { Activity } from 'models/Activity';
import { INITIAL_MG } from 'models/MG';
import { Ms4File } from 'models/Ms4File';
import React from 'react';
import { downloadFile } from 'utils';

interface ActivityCardProps {
  activity: Activity;
  attachments: Ms4File[];
  username: string;
  onClick?: () => void;
  onDelete: (activityId: string) => void;
  onDeleteAttachment: (activityId: string, attachmentId: number) => void;
}

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  description: {
    whiteSpace: 'pre-wrap',
  },
  cardContent: {
    paddingBottom: 0,
  },
  cardAction: {
    justifyContent: 'flex-end',
  },
  card: {
    cursor: 'pointer',
  },
}));

const ActivityCard: React.FC<ActivityCardProps> = ({
  activity,
  attachments,
  username,
  onClick,
  onDelete,
  onDeleteAttachment,
}) => {
  const classes = useStyles();
  const { mg = INITIAL_MG } = activity;

  return (
    <Box mb={2}>
      <Card className={classes.card} onClick={onClick}>
        <CardContent className={classes.cardContent}>
          <Box display="flex">
            <Typography variant="body1">
              {D.format(new Date(activity.date), 'MMMM d, yyyy')}
            </Typography>
            {mg.isQuantityRequired && (
              <>
                &nbsp;•&nbsp;
                <Typography variant="body1">
                  {mg.informationUsed}: {activity.quantity} {mg.units}
                </Typography>
              </>
            )}
            <Typography variant="body1">
              &nbsp;•&nbsp;
              {username}
            </Typography>
          </Box>
          <Box display="flex" mt={1}>
            <Typography variant="body1">
              {mg.number} {mg.bmp.title} • {mg.title}
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography
              className={classes.description}
              variant="body1"
              color="textSecondary">
              {activity.description}
            </Typography>
          </Box>
          <Box mt={2} display="flex" flexWrap="wrap">
            {(attachments || []).map((attachment, index) => (
              <Chip
                key={index}
                className={classes.chip}
                avatar={
                  <Avatar
                    alt="attachment"
                    src={
                      attachment.thumbnailUrl || require('assets/thumbnail.png')
                    }
                  />
                }
                label={
                  <Typography variant="body1">
                    {attachment.originalFileName}
                  </Typography>
                }
                onClick={(e) => {
                  e.stopPropagation();
                  downloadFile(attachment.originalFileName, attachment.fileUrl);
                }}
                onDelete={(e) => {
                  e.stopPropagation();
                  onDeleteAttachment(activity.id as string, attachment.id);
                }}
              />
            ))}
          </Box>
        </CardContent>

        <CardActions className={classes.cardAction}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onDelete(activity.id as string);
            }}>
            <DeleteIcon fontSize="small" color="error" />
          </IconButton>
        </CardActions>
      </Card>
    </Box>
  );
};

export default ActivityCard;
