import { Activity } from 'models/Activity';
import { BMP } from 'models/BMP';
import { MG } from 'models/MG';
import { SWMP } from 'models/SWMP';
import { useEffect, useState } from 'react';

export const useValidator = (
  entity: BMP | MG | SWMP | Activity,
  requiredFields: string[],
  isModalOpen: boolean,
  setEntity: React.Dispatch<React.SetStateAction<any>>,
  handleCompleteEntity: () => void
) => {
  const [validator, setValidator] = useState<{ [key: string]: boolean | null }>(
    {}
  );

  useEffect(() => {
    if (!isModalOpen) {
      return;
    }

    const validator = requiredFields.reduce((acc, field) => {
      if (
        !entity[field] ||
        (Array.isArray(entity[field]) && !entity[field].length)
      ) {
        acc[field] = null;
      } else {
        acc[field] = true;
      }
      return acc;
    }, {});

    setValidator(validator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleChange = (
    event: React.ChangeEvent<
      | HTMLInputElement
      | HTMLTextAreaElement
      | { name?: string | undefined; value: unknown }
    >
  ) => {
    const fieldName = event.target.name!;
    const checkedValue = (event.target as HTMLInputElement).checked;
    let value: any = event.target.value;

    switch (fieldName) {
      case 'year':
        if (!/^[0-9]*$/.test(value)) {
          value = entity[fieldName];
        }
        break;

      case 'startYear':
        value = parseInt(value);
        break;

      case 'minimumControlMeasures':
        const { minimumControlMeasures: bmpMcms } = entity as BMP;

        if (checkedValue) {
          value = [...bmpMcms, value];
        } else {
          value = bmpMcms.filter((val: string) => val !== value);
        }
        break;

      case 'isQuantityRequired':
        value = checkedValue;
        break;

      case 'yearsImplemented':
        const { yearsImplemented } = entity as MG;

        if (checkedValue) {
          value = [...yearsImplemented, parseInt(value)];
        } else {
          value = yearsImplemented.filter(
            (val: number) => val !== parseInt(value)
          );
        }
        break;

      default:
        break;
    }

    if (requiredFields.includes(fieldName)) {
      if (!value || (typeof value !== 'number' && !value.length)) {
        validator[fieldName] = false;
        setValidator(validator);
      } else if (!validator[fieldName]) {
        // to prevent set the same state
        validator[fieldName] = true;
        setValidator(validator);
      }
    }

    setEntity({
      ...entity,
      [fieldName]: value,
    });
  };

  const handleComplete = () => {
    const hasError = Object.values(validator).some((err) => !err);

    if (hasError) {
      Object.keys(validator).forEach((field) => {
        if (validator[field] === null) {
          validator[field] = false;
        }
      });

      return setValidator({ ...validator });
    }

    handleCompleteEntity();
  };

  return {
    validator,
    handleChange,
    handleComplete,
  };
};
