import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import {
  SwmpAccess,
  SwmpActive,
  SwmpAnnualReport,
  SwmpDetail,
  SwmpDraft,
  SwmpList,
} from 'containers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';

interface AppProps {
  loading: boolean;
  error: string;
  onClearError: () => void;
}

const App: React.FC<AppProps> = ({ loading, error, onClearError }) => {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (!error) {
      showError && setShowError(false);
      return;
    }

    setShowError(true);
  }, [error, showError]);

  return (
    <React.Fragment>
      {loading && <LinearProgress color="secondary" />}

      <Container maxWidth="md">
        <Box m={3}>
          <Router>
            <Switch>
              <Route exact path="/">
                <SwmpActive />
              </Route>
              <Route exact path="/swmp">
                <SwmpList />
              </Route>
              <Route exact path="/swmp/:id">
                <SwmpDetail />
              </Route>
              <Route exact path="/swmp/draft/:id/:step">
                <SwmpDraft />
              </Route>
              <Route exact path="/swmp/:id/:accessType/:step">
                <SwmpAccess />
              </Route>
              <Route exact path="/swmp/:swmpId/:reportId/annualReport/:step">
                <SwmpAnnualReport />
              </Route>
            </Switch>
          </Router>
        </Box>
      </Container>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showError}
        onClose={onClearError}
        message={error}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  error: select.error(state),
  loading: select.loading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onClearError: () => dispatch(actions.clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
