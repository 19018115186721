import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import {
  AddButton,
  BmpCard,
  LabelContainer,
  TextField,
  Textarea,
} from 'components';
import { useModal, useValidator } from 'hooks';
import { BMP, INITIAL_BMP } from 'models/BMP';
import { MG } from 'models/MG';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import {
  createBmp,
  createMg,
  deleteBmp,
  deleteMg,
  updateBmp,
  updateMg,
} from 'services';
import { getStepRoute } from 'utils';
import * as uuid from 'uuid';

import { useStyles } from './SwmpGeneralInfo';

interface SwmpAddBmpProps {
  planLength: number;
  bstMgtPracs: BMP[];
  measurableGoals: MG[];
  onUpdateBmp: (bmp: BMP[]) => void;
  onUpdateMg: (mg: MG[]) => void;
  onSetError: (error: string) => void;
}

const SwmpAddBmp: React.FC<SwmpAddBmpProps> = ({
  planLength,
  bstMgtPracs,
  measurableGoals,
  onUpdateBmp,
  onUpdateMg,
  onSetError,
}) => {
  const [isAddModal, setIsAddModal] = useState<boolean>(true); // Add modal when the value is true. Otherwise it's a change modal
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [bmp, setBmp] = useState<BMP>(INITIAL_BMP);
  const { Modal, title } = useModal(isAddModal, true);
  const { id: swmpId, step, accessType } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const handleCompleteBmp = async () => {
    try {
      if (isAddModal) {
        const newBmp = accessType
          ? { id: uuid.v4(), swmpId }
          : await createBmp({
              ...bmp,
              swmpId,
            });

        onUpdateBmp([
          {
            ...bmp,
            swmpId: newBmp.swmpId,
            id: newBmp.id,
          },
          ...bstMgtPracs,
        ]);
      } else {
        !accessType && (await updateBmp(bmp));
        const selectedBmpIndex = bstMgtPracs.findIndex((b) => b.id === bmp.id);
        bstMgtPracs[selectedBmpIndex] = bmp;
        onUpdateBmp(bstMgtPracs);
      }

      setIsModalOpen(false);
      setBmp(INITIAL_BMP);
    } catch (error) {
      onSetError(error.response.data.message);
    }
  };

  const handleCompleteMg = async (
    measurableGoal: MG,
    isAdd: boolean,
    bmp: BMP
  ) => {
    if (isAdd) {
      const createdMg = accessType
        ? {
            id: uuid.v4(),
          }
        : await createMg({
            ...measurableGoal,
            bmpId: bmp.id,
          });
      const newMg: MG = {
        ...measurableGoal,
        id: createdMg.id,
        bmp: {
          id: bmp.id,
          title: bmp.title,
          minimumControlMeasures: bmp.minimumControlMeasures,
        },
      };
      const updatedBmpIndex = bstMgtPracs.findIndex((b) => b.id === bmp.id);
      bstMgtPracs[updatedBmpIndex].measurableGoals = bstMgtPracs[
        updatedBmpIndex
      ].measurableGoals.concat(newMg);

      onUpdateMg(measurableGoals.concat(newMg));
      onUpdateBmp(bstMgtPracs);
    } else {
      !accessType &&
        (await updateMg({
          ...measurableGoal,
          bmpId: bmp.id,
        }));
      const mgIndex = measurableGoals.findIndex(
        (mg) => mg.id === measurableGoal.id
      );
      measurableGoals[mgIndex] = measurableGoal;
      const updatedBmpIndex = bstMgtPracs.findIndex((b) => b.id === bmp.id);
      const assignedBmpMgIndex = bstMgtPracs[
        updatedBmpIndex
      ].measurableGoals.findIndex((mg) => mg.id === measurableGoal.id);
      bstMgtPracs[updatedBmpIndex].measurableGoals[
        assignedBmpMgIndex
      ] = measurableGoal;

      onUpdateMg(measurableGoals);
      onUpdateBmp([...bstMgtPracs]);
    }
  };

  const handleRemoveMg = async (mgId: string, bmpId: string) => {
    !accessType && (await deleteMg(mgId));
    const updatedBmpIndex = bstMgtPracs.findIndex((bmp) => bmp.id === bmpId);
    bstMgtPracs[updatedBmpIndex].measurableGoals = bstMgtPracs[
      updatedBmpIndex
    ].measurableGoals.filter((mg) => mg.id !== mgId);

    onUpdateMg(measurableGoals.filter((mg) => mg.id !== mgId));
    onUpdateBmp(bstMgtPracs);
  };

  const handleRemoveBmp = async (bmpId: string) => {
    !accessType && (await deleteBmp(bmpId));
    onUpdateBmp(bstMgtPracs.filter((bmp) => bmp.id !== bmpId));
  };

  const { validator, handleChange, handleComplete } = useValidator(
    bmp,
    ['number', 'title', 'minimumControlMeasures'],
    isModalOpen,
    setBmp,
    handleCompleteBmp
  );

  const showAddButton = useMemo(() => {
    return !accessType || accessType === 'update';
  }, [accessType]);

  return (
    <>
      {showAddButton && (
        <AddButton
          text="Add Best Management Practice"
          textVariant="h6"
          fullWidth
          onClick={() => {
            setIsAddModal(true);
            setIsModalOpen(true);
          }}
        />
      )}
      <BmpCard
        bmps={bstMgtPracs}
        onEditBmp={(bmpId) => {
          setBmp(bstMgtPracs.find((bmp) => bmp.id === bmpId)!);
          setIsAddModal(false);
          setIsModalOpen(true);
        }}
        onRemoveBmp={handleRemoveBmp}
        onCompleteMg={handleCompleteMg}
        onRemoveMg={handleRemoveMg}
        showUpdateButton={showAddButton}
        planLength={planLength}
      />

      <Box display="flex" justifyContent="space-between" mt={6}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            <ArrowBackIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, null, step, false, accessType))
          }>
          General Information
        </Button>
        {showAddButton && (
          <Button
            variant="contained"
            color="secondary"
            endIcon={
              <ArrowForwardIcon
                className={classes.buttonIcon}
                fontSize="small"
              />
            }
            onClick={() =>
              history.push(getStepRoute(swmpId, null, step, true, accessType))
            }>
            {accessType === 'update' ? 'Apply Updates' : 'Start Permit'}
          </Button>
        )}
      </Box>

      <Modal
        title={title}
        open={isModalOpen}
        onComplete={handleComplete}
        onCancel={() => {
          setBmp(INITIAL_BMP);
          setIsModalOpen(false);
        }}>
        <Box display="flex" justifyContent="space-between">
          <Box flexBasis="25%" mr={4}>
            <TextField
              fullWidth
              label="Number"
              name="number"
              value={bmp.number}
              placeholder="1.1"
              onChange={handleChange}
              helperText={
                validator['number'] === false ? 'Number is required' : ''
              }
            />
          </Box>
          <Box flex="1">
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={bmp.title}
              placeholder="Brief title describing the best management practice"
              onChange={handleChange}
              helperText={
                validator['title'] === false ? 'Title is required' : ''
              }
            />
          </Box>
        </Box>
        <LabelContainer label="Minimum Control Measures (MCM)" mt={3}>
          {Array.from({ length: 6 }).map((_, index) => (
            <Box display="inline" key={index} mr={2}>
              <FormControlLabel
                value={index + 1}
                name="minimumControlMeasures"
                control={
                  <Checkbox
                    checked={bmp.minimumControlMeasures.includes(
                      (index + 1).toString()
                    )}
                    onChange={handleChange}
                  />
                }
                label={(index + 1).toString()}
              />
            </Box>
          ))}
          <Box display="inline">
            <FormControlLabel
              value="TMDL"
              name="minimumControlMeasures"
              control={
                <Checkbox
                  checked={bmp.minimumControlMeasures.includes('TMDL')}
                  onChange={handleChange}
                />
              }
              label="TMDL"
            />
          </Box>
          <Typography className={classes.error} variant="body2">
            {validator['minimumControlMeasures'] === false
              ? 'MCM is required'
              : ''}
          </Typography>
        </LabelContainer>
        <LabelContainer label="Year Implemented" mt={3}>
          <RadioGroup
            value={bmp.startYear}
            name="startYear"
            onChange={handleChange}>
            <FormControlLabel label="Existing" value={0} control={<Radio />} />
            {Array.from({ length: planLength }).map((_, index) => (
              <Box key={index} mr={2} ml={!index ? 2 : 0}>
                <FormControlLabel
                  label={(index + 1).toString()}
                  value={index + 1}
                  control={<Radio />}
                />
              </Box>
            ))}
          </RadioGroup>
        </LabelContainer>
        <Box mt={3}>
          <Textarea
            label="Description"
            name="description"
            value={bmp.description}
            placeholder="Provide a full description of the best management practice"
            onChange={handleChange}
          />
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  planLength: select.planLength(state),
  bstMgtPracs: select.bstMgtPracs(state),
  measurableGoals: select.measurableGoals(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateBmp: (bmp: BMP[]) => dispatch(actions.updateBmp(bmp)),
  onUpdateMg: (mg: MG[]) => dispatch(actions.updateMg(mg)),
  onSetError: (error: string) => dispatch(actions.setError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwmpAddBmp);
