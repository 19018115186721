import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { SwmpStep, Modal } from 'components';
import { startCase, upperFirst } from 'lodash';
import { cloneDeep } from 'lodash';
import { SWMPAddStep } from 'models';
import { SWMP_ADD_STEPS } from 'models/constants';
import { Customer } from 'models/Customer';
import { INITIAL_SWMP, SWMP } from 'models/SWMP';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import { fetchSwmpDetails, deleteSwmp } from 'services';
import { updateSwmp } from 'services';

import SwmpAddBmp from './SwmpAddBmp';
import SwmpGeneralInfo from './SwmpGeneralInfo';
import SwmpStart from './SwmpStart';

interface SwmpDraftProps {
  swmp: SWMP;
  customer: Customer;
  onLoad: (swmpId: number) => Promise<void>;
  onSave: (swmp: SWMP) => Promise<void>;
}

const SwmpDraft: React.FC<SwmpDraftProps> = ({
  swmp,
  customer,
  onLoad,
  onSave,
}) => {
  const { id: swmpId, step, accessType } = useParams();
  const history = useHistory();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    onLoad(swmpId);
  }, [onLoad, swmpId]);

  const handleDeleteSwmp = async () => {
    await deleteSwmp(swmp.id);

    history.push('/swmp');
  };

  let stepContainer: React.ReactNode;

  switch (upperFirst(step)) {
    case SWMPAddStep.General:
      stepContainer = <SwmpGeneralInfo />;
      break;

    case SWMPAddStep.Bmp:
      stepContainer = <SwmpAddBmp />;
      break;

    case SWMPAddStep.Start:
      stepContainer = <SwmpStart />;
      break;

    default:
      break;
  }

  const totalSteps = useMemo(() => {
    if (!accessType) {
      return SWMP_ADD_STEPS;
    }

    if (accessType === 'view') {
      return SWMP_ADD_STEPS.slice(0, 2);
    }

    const swmpUpdateSteps = cloneDeep(SWMP_ADD_STEPS);
    swmpUpdateSteps[2].text = 'Apply Updates';

    return swmpUpdateSteps;
  }, [accessType]);

  const subTitle = useMemo(() => {
    if (!accessType) {
      return 'Draft';
    }

    if (accessType === 'update') {
      return 'Apply Updates';
    }

    return '';
  }, [accessType]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={6}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<ArrowBackIcon color="secondary" fontSize="small" />}
          onClick={() => {
            !accessType && onSave(swmp);
            history.push(accessType ? `/swmp/${swmpId}` : '/swmp');
          }}>
          Stormwater Management Plan{accessType ? ` ${swmp.year}` : 's'}
        </Button>

        {!accessType && customer.admin && (
          <Button variant="outlined" onClick={() => setIsDeleteModalOpen(true)}>
            Delete
          </Button>
        )}
      </Box>

      <Typography variant="subtitle1" align="center">
        Stormwater Management Plan {swmp.year}
      </Typography>
      <Box mb={4}>
        <Typography variant="h6" align="center" color="textSecondary">
          {subTitle}
        </Typography>
      </Box>
      <SwmpStep
        swmpId={swmp.id}
        currentStep={startCase(step)}
        totalSteps={totalSteps}
      />

      {stepContainer}

      <Modal
        open={isDeleteModalOpen}
        title="Are you sure you want to delete?"
        completeActionText="Delete"
        onCancel={() => setIsDeleteModalOpen(false)}
        onComplete={handleDeleteSwmp}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  swmp: select.swmp(state),
  customer: select.customer(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: async (swmpId: number) => {
    const { bmp, mg, swmp, customer } = await fetchSwmpDetails(swmpId);

    dispatch(actions.setCustomer(customer));
    dispatch(actions.updateSwmp(swmp));
    dispatch(actions.updateBmp(bmp));
    dispatch(actions.updateMg(mg));
  },
  onSave: async (swmp: SWMP) => {
    await updateSwmp(swmp);

    dispatch(actions.updateSwmp(INITIAL_SWMP));
    dispatch(actions.updateBmp([]));
    dispatch(actions.updateMg([]));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SwmpDraft);
