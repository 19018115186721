import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';
import { LabelContainer, TextField } from 'components';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'redux/actions';
import select from 'redux/select';
import { getStepRoute, removeAnnualReportEntity } from 'utils';
import * as uuid from 'uuid';

import { useStyles } from './SwmpComplianceStatus';
import { SwmpAnnualReportPromptProps } from '.';

const SwmpAdditionalInformation: React.FC<SwmpAnnualReportPromptProps> = ({
  annualReport,
  onUpdateAnnualReport,
}) => {
  const { additionalInformation } = annualReport;
  const classes = useStyles();
  const history = useHistory();
  const { swmpId, step, reportId } = useParams();
  const [entityName, setEntityName] = useState('');
  const [authorizationNumber, setAuthorizationNumber] = useState('');

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}>
        <Box flexBasis="80%">
          <Typography>
            1. Is the permittee relying on another entity to satisfy any permit
            obligations?
          </Typography>
        </Box>
        <RadioGroup
          value={additionalInformation.isPermitteeRelyingOnEntities}
          onChange={(e) =>
            onUpdateAnnualReport(
              'isPermitteeRelyingOnEntities',
              e.target.value === 'true'
            )
          }>
          <FormControlLabel label="YES" value={true} control={<Radio />} />
          <FormControlLabel label="NO" value={false} control={<Radio />} />
        </RadioGroup>
      </Box>

      {additionalInformation.isPermitteeRelyingOnEntities && (
        <Box mb={1}>
          <LabelContainer label="Provide the name(s) of other entities and an explanation of their responsibilities">
            <Box display="flex" justifyContent="space-between">
              <Box flexBasis="85%">
                <TextField
                  noMb
                  fullWidth
                  placeholder="Name"
                  value={entityName}
                  onChange={(e) => setEntityName(e.target.value)}
                />
              </Box>
              <Button
                className={classes.addButton}
                variant="contained"
                color="secondary"
                endIcon={
                  <AddIcon className={classes.buttonIcon} fontSize="small" />
                }
                onClick={() => {
                  if (!entityName) {
                    return;
                  }

                  const index = additionalInformation.relyingEntities.length;

                  onUpdateAnnualReport(`relyingEntities.${index}`, {
                    id: uuid.v4(),
                    name: entityName,
                    explanation: '',
                  });
                }}>
                Add
              </Button>
            </Box>
          </LabelContainer>

          {additionalInformation.relyingEntities.map((e, index) => (
            <Box key={e.id}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                my={1}>
                <Box flexBasis="85%" flexWrap="wrap">
                  <Typography>Name: {e.name}</Typography>
                </Box>
                <IconButton
                  onClick={() =>
                    removeAnnualReportEntity(
                      'relyingEntities',
                      index,
                      additionalInformation,
                      onUpdateAnnualReport
                    )
                  }>
                  <DeleteIcon color="secondary" />
                </IconButton>
              </Box>
              <TextField
                fullWidth
                noMb
                label="Explanation"
                placeholder="Explanation"
                multiline
                rows={5}
                value={e.explanation}
                onChange={(e) =>
                  onUpdateAnnualReport(
                    `relyingEntities.${index}.explanation`,
                    e.target.value
                  )
                }
              />
            </Box>
          ))}
        </Box>
      )}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}>
        <Box flexBasis="80%">
          <Typography>
            2.a. Is the permittee part of a group sharing a SWMP with other
            entities?
          </Typography>
        </Box>
        <RadioGroup
          value={additionalInformation.isPermitteePartOfGroup}
          onChange={(e) =>
            onUpdateAnnualReport(
              'isPermitteePartOfGroup',
              e.target.value === 'true'
            )
          }>
          <FormControlLabel label="YES" value={true} control={<Radio />} />
          <FormControlLabel label="NO" value={false} control={<Radio />} />
        </RadioGroup>
      </Box>

      {additionalInformation.isPermitteePartOfGroup && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={1}>
          <Box flexBasis="80%">
            <Typography>
              2.b. Is this a system-wide annual report including information for
              all permittees?
            </Typography>
          </Box>
          <RadioGroup
            value={additionalInformation.isReportIncludingAllPermittees}
            onChange={(e) =>
              onUpdateAnnualReport(
                'isReportIncludingAllPermittees',
                e.target.value === 'true'
              )
            }>
            <FormControlLabel label="YES" value={true} control={<Radio />} />
            <FormControlLabel label="NO" value={false} control={<Radio />} />
          </RadioGroup>
        </Box>
      )}

      {additionalInformation.isPermitteePartOfGroup &&
        additionalInformation.isReportIncludingAllPermittees && (
          <>
            <LabelContainer label="List all associated authorization numbers, permittee names, and SWMP responsibilities of each member">
              <Box display="flex" justifyContent="space-between">
                <Box flexBasis="85%">
                  <TextField
                    noMb
                    fullWidth
                    placeholder="Authorization Number"
                    value={authorizationNumber}
                    onChange={(e) => setAuthorizationNumber(e.target.value)}
                  />
                </Box>
                <Button
                  className={classes.addButton}
                  variant="contained"
                  color="secondary"
                  endIcon={
                    <AddIcon className={classes.buttonIcon} fontSize="small" />
                  }
                  onClick={() => {
                    if (!authorizationNumber) {
                      return;
                    }

                    const index =
                      additionalInformation.associatedResponsibilities.length;

                    onUpdateAnnualReport(
                      `associatedResponsibilities.${index}`,
                      {
                        id: uuid.v4(),
                        authorizationNumber,
                        permittee: '',
                      }
                    );
                  }}>
                  Add
                </Button>
              </Box>
            </LabelContainer>
            {additionalInformation.associatedResponsibilities.map(
              (r, index) => (
                <Box key={r.id}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    my={1}>
                    <Box flexBasis="85%" flexWrap="wrap">
                      <Typography>
                        Authorization Number: {r.authorizationNumber}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() =>
                        removeAnnualReportEntity(
                          'associatedResponsibilities',
                          index,
                          additionalInformation,
                          onUpdateAnnualReport
                        )
                      }>
                      <DeleteIcon color="secondary" />
                    </IconButton>
                  </Box>
                  <TextField
                    noMb
                    fullWidth
                    label="Permittee"
                    placeholder="Permittee"
                    defaultValue={r.permittee}
                    onBlur={(e) =>
                      onUpdateAnnualReport(
                        `associatedResponsibilities.${index}.permittee`,
                        e.target.value
                      )
                    }
                  />
                </Box>
              )
            )}
          </>
        )}

      <Box display="flex" justifyContent="space-between" mt={6}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            <ArrowBackIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, false))
          }>
          Additional BMPs for TMDLs and I-Plans
        </Button>
        <Button
          variant="contained"
          color="secondary"
          endIcon={
            <ArrowForwardIcon className={classes.buttonIcon} fontSize="small" />
          }
          onClick={() =>
            history.push(getStepRoute(swmpId, reportId, step, true))
          }>
          Construction Activities
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  annualReport: select.annualReport(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAnnualReport: (path: string, value: any) =>
    dispatch(
      actions.updateAnnualReport(`additionalInformation.${path}`, value)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwmpAdditionalInformation);
