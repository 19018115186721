import { DialogProps } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import React from 'react';

export interface ModalProps extends DialogProps {
  title: string;
  completeActionText: string;
  onComplete: () => void;
  onCancel: () => void;
}

const useStyles = makeStyles({
  cancelButton: {
    border: 'none',
  },
});

const Modal: React.FC<ModalProps> = ({
  open,
  title,
  completeActionText,
  onComplete,
  onCancel,
  children,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Box>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            variant="outlined"
            fullWidth
            startIcon={<Close fontSize="small" />}
            onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={onComplete}>
            {completeActionText}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export const AddModal: React.FC<Omit<ModalProps, 'completeActionText'>> = (
  props
) => <Modal {...props} completeActionText="Add" />;

export const SaveModal: React.FC<Omit<ModalProps, 'completeActionText'>> = (
  props
) => <Modal {...props} completeActionText="Save" />;

export default Modal;
